:root {
  --black: #000000;
  --font-fgnew: "FoundryGridnikBold";
  --font-fg: "FoundryGridnikW03Bold";
  --font-en: "EditorialNewRegular400";
  --font-fc: "FixtureCondensedSemiBold";
  --font-helvetica: "Helvetica";
  --bg-primary: #d7d8e2;
  --bg-info: #d4e1de;
  --bg-warning: #ded1af;
  --bg-success: #b2cfdb;
  --bg-muted: #acb7a7;
  --bg-secondary: #dbb9a8;
}

@font-face {
  font-family: "EditorialNewRegular400";
  src: url("../fonts/EditorialNewRegular400.eot");
  src: url("../fonts/EditorialNewRegular400.eot") format("embedded-opentype"), url("../fonts/EditorialNewRegular400.woff2") format("woff2"), url("../fonts/EditorialNewRegular400.woff") format("woff"), url("../fonts/EditorialNewRegular400.ttf") format("truetype"), url("../fonts/EditorialNewRegular400.svg#EditorialNewRegular400") format("svg");
}

@font-face {
  font-family: "FoundryGridnikW03Bold";
  src: url("../fonts/FoundryGridnikW03Bold.eot");
  src: url("../fonts/FoundryGridnikW03Bold.eot") format("embedded-opentype"), url("../fonts/FoundryGridnikW03Bold.woff2") format("woff2"), url("../fonts/FoundryGridnikW03Bold.woff") format("woff"), url("../fonts/FoundryGridnikW03Bold.ttf") format("truetype"), url("../fonts/FoundryGridnikW03Bold.svg#FoundryGridnikW03Bold") format("svg");
}

@font-face {
  font-family: "FoundryGridnikBold";
  src: url("../fonts/FoundryGridnikBold.eot");
  src: url("../fonts/FoundryGridnikBold.eot") format("embedded-opentype"), url("../fonts/FoundryGridnikBold.woff2") format("woff2"), url("../fonts/FoundryGridnikBold.woff") format("woff"), url("../fonts/FoundryGridnikBold.ttf") format("truetype")
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.eot");
  src: url("../fonts/Helvetica.eot") format("embedded-opentype"), url("../fonts/Helvetica.woff2") format("woff2"), url("../fonts/Helvetica.woff") format("woff"), url("../fonts/Helvetica.ttf") format("truetype"), url("../fonts/Helvetica.svg#Helvetica") format("svg");
}

@font-face {
  font-family: "EditorialNewItalic";
  src: url("../fonts/EditorialNewItalic.eot");
  src: url("../fonts/EditorialNewItalic.eot") format("embedded-opentype"), url("../fonts/EditorialNewItalic.woff2") format("woff2"), url("../fonts/EditorialNewItalic.woff") format("woff"), url("../fonts/EditorialNewItalic.ttf") format("truetype"), url("../fonts/EditorialNewItalic.svg#EditorialNewItalic") format("svg");
}

@font-face {
  font-family: "FixtureCondensedSemiBold";
  src: url("../fonts/FixtureCondensedSemiBold.eot");
  src: url("../fonts/FixtureCondensedSemiBold.eot") format("embedded-opentype"), url("../fonts/FixtureCondensedSemiBold.woff2") format("woff2"), url("../fonts/FixtureCondensedSemiBold.woff") format("woff"), url("../fonts/FixtureCondensedSemiBold.ttf") format("truetype"), url("../fonts/FixtureCondensedSemiBold.svg#FixtureCondensedSemiBold") format("svg");
}

@font-face {
  font-family: "Public Sans";
  src: url("../fonts/PublicSans-Regular.eot");
  src: url("../fonts/PublicSans-Regular.eot") format("embedded-opentype"), url("../fonts/PublicSans-Regular.woff2") format("woff2"), url("../fonts/PublicSans-Regular.woff") format("woff"), url("../fonts/PublicSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Public Sans Medium";
  src: url("../fonts/PublicSans-Medium.eot");
  src: url("../fonts/PublicSans-Medium.eot") format("embedded-opentype"), url("../fonts/PublicSans-Medium.woff2") format("woff2"), url("../fonts/PublicSans-Medium.woff") format("woff"), url("../fonts/PublicSans-Medium.ttf") format("truetype");
}

/*Adharsh*/
/*grid of 2 columns*/
.subscription-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.pricing-card {
  border: 1px solid #000;
  padding: 20px;
  width: 300px;
  position: relative;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.recommended-banner {
  position: absolute;
  top: 70px;
  right: -65px;
  background: black;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(45deg);
  width: 292px;
  text-align: center;
}

.recommended-banner span {
  font-family: "FoundryGridnikBold";
  letter-spacing: 5px;
}

.info-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.pricing-details h1 {
  font-size: 75.43px;
  margin: 20px 0 10px;
}

.price {
  font-size: 46px;
  font-family: "EditorialNewRegular400";
  font-style: italic;
  margin-top: 24px;
}

.only {
  font-size: 18.43px;
  font-weight: normal;
  font-style: normal;
  font-family: "FoundryGridnikBold";
}

.per-day {
  font-size: 14px;
  margin-top: 10px;
}

.buy-now-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  font-family: "Public Sans";
}

.buy-now-button:hover {
  background-color: #333;
}


@media (max-width: 1199.98px) {
  .subscription-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

}

.post-single img {
  object-fit: cover;
  width: 100% !important;
}

/*Category links*/
.category-tab-list {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.most-interest-item {
  position: relative;
}

.subscribetext {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}

.signup-banner {}

.signup-banner p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}

.bottom-border {
  width: 99%;
  /* Border length set to 70% of the parent div */
  border-bottom: 1px solid black;
  /* Bottom border */
  margin: 0 auto;
  /* Centers the border horizontally */
}



/*hide border if mobile view*/
@media (max-width: 768px) {
  .bottom-border {
    display: none;
  }

  .bottom-border.remove-border {
    border: none;
  }
}

.article-excerpt {
  font-size: 17.43px;
}

.category-tab-list div.active .category-tab-link::before {
  left: 0;
  width: 100%;
}

.category-tab-list div:hover .category-tab-link::before,
.category-tab-list div.active .category-tab-link::before {
  left: 0;
  width: 100%;
}


/* =================reset css starts here=================  */
* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
body,
html,
form,
fieldset {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}

a {
  text-decoration: none;
  border: 0;
  outline: 0;
}

ul {
  list-style: none;
}

a:focus,
input:focus,
textarea:focus,
*:focus {
  outline: 0 !important;
}

.reading-progress-bar {
  position: sticky;
  height: 5px;
  top: 10;
  background-color: #ff0000;
}

/* =================reset css ends here================= */

/* ===============opacity add in article start================ */
.paywall {
  box-shadow: 0 -100px 50px 40px hsl(0deg 0% 100% / 95%);
  margin-bottom: -100px;
  padding-bottom: 100px;
  position: relative;
}

@media screen and (max-width: 468px) {
  .paywall {
    font-size: 14px;
  }
}


/*=================CROSSWORD===================*/
.crossword-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  gap: 2px;
}

.cell {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
}

.black-cell {
  background-color: black;
}

.white-cell {
  background-color: white;
}


/* ===============opacity add in article end================ */

/* =================slick css ends here================= */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff center center no-repeat;
}

.slick-prev,
.slick-next {
  z-index: 99;
  font-size: 0;
  line-height: 26px;
  position: absolute;
  top: 27%;
  display: block;
  float: left;
  padding: 0;
  margin-top: -25px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #fff !important;
  width: 18px;
  height: 19px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgb(0 0 0 / 16%);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.3;
}

.slick-prev:before,
.slick-next:before {
  font-family: "font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  line-height: 1;
  opacity: 0.9;
  color: #000 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 0;
}

.slick-prev {
  left: 20px;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next {
  right: 17px;
}

.slick-next:before {
  content: "\f054";
}

.slick-slider {
  margin-bottom: 0;
  padding-bottom: 45px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  vertical-align: top;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  opacity: 1;
  border: solid 1px #000;
  border-radius: 50%;
  vertical-align: top;
  margin: 5px 6px;
}

.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
  background-color: #000;
}

.slick-arrow.slick-disabled,
.slick-arrow.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

/* =================slick css end here=================  */

/* =================core css starts here================= */
body {
  font-size: 17px;
  line-height: 23px;
  font-weight: 400;
  font-family: "PT Serif", serif;
  color: #000;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: calc(100% - 12px);
  max-width: 1090px;
  padding: 0 15px;
}

.container-fluid {
  width: calc(100% - 80px);
  padding: 0 15px;
}

a,
input,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  outline: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

a img {
  border: 0px none;
}

a:hover {
  outline: none;
  color: #333;
  text-decoration: none;
}

a:active {
  outline: none;
  text-decoration: none;
}

a:focus {
  outline: none;
  outline-offset: 0px;
  text-decoration: none;
  color: inherit;
}

::-webkit-input-placeholder {
  color: #000;
  opacity: 0.5;
}

::-moz-placeholder {
  color: #000;
  opacity: 0.5;
}

:-moz-placeholder {
  color: #000;
  opacity: 0.5;
}

:-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}

.os-animation {
  opacity: 0;
}

.os-animation.animated {
  opacity: 1;
}

.row>* {
  padding: 0 15px;
}

img {
  max-width: 100%;
  border: 0;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 58px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 26px;
}

h6 {
  font-size: 22px;
}

p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 18px;
}

strong {
  font-weight: 700;
}

b {
  font-weight: 400;
}

p:last-child {
  margin-bottom: 0;
}

.btn {
  border-radius: 0;
  font-weight: 300;
  padding: 7px 15px 7px;
  text-transform: uppercase;
  min-width: 140px;
  font-family: "Public Sans";
  font-size: 19px;
  line-height: 21px;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

.btn-default,
.btn-default:focus {
  color: #fff;
  background-color: var(--black);
  border: solid 1px var(--black);
}

.btn-default:hover,
.btn-default:active {
  color: #fff;
  background-color: #353333;
  border-color: #353333;
}

.btn.btn-sm {
  width: 100%;
  height: 28px;
  line-height: 10px !important;
  font-size: 15px !important;
}

.btn.btn-block {
  min-width: 100%;
}

.btn+.btn {
  margin-left: 15px;
}

.btn.btn-lg {
  font-size: 24px !important;
  height: 46px;
  line-height: 31px;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

ul:not([class]) {
  list-style: none;
  margin-bottom: 15px;
  text-align: left;
  line-height: 22px;
}

ul:not([class])>li {
  padding-left: 16px;
  position: relative;
  margin-bottom: 14px;
}

ul:not([class])>li:last-child {
  margin-bottom: 0;
}

ul:not([class])>li:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 10px;
  /* background: #000; */
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.form-control {
  height: 46px;
  font-size: 18px;
  line-height: 22px;
  padding: 8px 20px;
  background-color: transparent !important;
  border: 1px solid #040505 !important;
  font-family: "Public Sans";
  border-radius: 0;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: rgb(0 0 0 / 50%);
}

.section-heading {
  margin-bottom: 36px;
  position: relative;
  display: flex;
  justify-content: center;
}

.section-heading::before {
  content: "";
  position: absolute;
  width: 100%;
  background: #000;
  height: 1.5px;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.section-heading h5 {
  font-size: 11.13px;
  line-height: 14px;
  margin-bottom: 0;
  padding: 0 44px;
  position: relative;
  background: #fff;
  letter-spacing: 4.21px;
  font-family: var(--font-fgnew);
  display: inline-block;
  text-transform: uppercase;
}

.section-heading h5>span {
  position: absolute;
  width: 14px;
  top: 48%;
  transform: translateY(-50%);
  height: 15px;
}

.section-heading h5>span>img {
  width: 100%;
  vertical-align: middle;
}

.section-heading h5>span.left {
  left: -2px;
}

.section-heading h5>span.right {
  right: -2px;
}

.category-heading {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.category-heading-box {
  padding-left: 130px;
  position: relative;
  margin: 0;
  min-height: 123px;
  display: flex;
  align-items: center;
}

.category-heading-box img {
  width: auto;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  max-width: 120px;
  top: 0;
}

.category-heading h3 {
  font-size: 52px;
  line-height: 60px;
  margin-bottom: 0;
  font-family: "EditorialNewRegular400";
  text-transform: uppercase;
}

.post-date .add-btn {
  margin-left: 6px;
}

.add-btn {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  margin-left: 5px;
}

.add-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.delete-btn {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  margin-left: 5px;
}

.delete-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.heading {
  margin-bottom: 40px;
}

.heading h4 {
  font-size: 50px;
  line-height: 55px;
  margin-bottom: 0;
}

.gray-bg {
  background-color: #dfdfde;
}

.yellow-bg {
  background-color: #fde500;
}

.yellow-dark-bg {
  background-color: #ffdb00;
}

/* =================core css ends here================= */
.form-group+.form-group {
  margin-top: 16px;
}

.form-group.form-check {
  padding-left: 0;
}

.form-check input[type="checkbox"] {
  display: none;
}

.form-check input[type="checkbox"]+label {
  display: inline-block;
  position: relative;
  padding-left: 34px;
  font-size: 14px !important;
  line-height: 23px !important;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
  cursor: pointer;
  font-family: "Public Sans";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.form-check input[type="checkbox"]+label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 1px solid #606b75;
}

.form-check input[type="checkbox"]+label:after {
  content: "";
  display: inline-block;
  font-weight: 900;
  width: 8px;
  height: 8px;
  line-height: 20px;
  background: #000;
  text-align: center;
  position: absolute;
  left: 4px;
  border-radius: 50%;
  top: 4px;
  transform: scale(0);
  -moz-transition: all 0.12s ease-in;
  -o-transition: all 0.12s ease-in;
  -webkit-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
}

.form-check input[type="checkbox"]+label:after {
  color: #fff;
}

.form-check input[type="checkbox"]:checked+label:after {
  transform: scale(1);
}

.form-check input[type="radio"] {
  display: none;
}

.form-check input[type="radio"]+label {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  font-size: 11px !important;
  line-height: 17px !important;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
  cursor: pointer;
  font-family: "Public Sans";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.form-check input[type="radio"]+label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 1px solid #606b75;
}

.form-check input[type="radio"]+label:after {
  content: "";
  display: inline-block;
  font-weight: 900;
  width: 8px;
  height: 8px;
  line-height: 20px;
  background: #000;
  text-align: center;
  position: absolute;
  left: 4px;
  border-radius: 50%;
  top: 4px;
  transform: scale(0);
  -moz-transition: all 0.12s ease-in;
  -o-transition: all 0.12s ease-in;
  -webkit-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
}

.form-check input[type="radio"]+label:after {
  color: #fff;
}

.form-check input[type="radio"]:checked+label:after {
  transform: scale(1);
}

.content-container {
  padding: 50px 0;
}

/*.content-container.less-pad { padding: 40px 0; }
   .content-container.more-pad { padding: 92px 0; }*/
.simple-heading {
  margin-bottom: 32px;
}

.simple-heading h4 {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 6.3px;
  font-family: "FoundryGridnikW03Bold";
  font-style: normal;
  margin-bottom: 0;
}

.page-heading {
  margin-bottom: 30px;
  text-align: center;
}

.page-heading h1 {
  font-size: 54px;
  line-height: 64px;
  font-family: "EditorialNewRegular400";
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

.page-heading h3 {
  font-size: 24px;
  line-height: 30px;
  font-family: "EditorialNewRegular400";
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

.bg-primary {
  background-color: var(--bg-primary) !important;
}

.bg-secondary {
  background-color: var(--bg-secondary) !important;
}

.bg-success {
  background-color: var(--bg-success) !important;
}

.bg-warning {
  background-color: var(--bg-warning) !important;
}

.bg-info {
  background-color: var(--bg-info) !important;
}

.bg-muted {
  background-color: var(--bg-muted) !important;
}

/* ================= Header Start ================= */
#header {
  background-color: #fff;
  padding: 0;
  z-index: 9999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

header .navbar {
  padding: 0;
  width: 100%;
  height: 201px;
  transition: all 0.2s ease-in;
  padding: 56px 0 20px;
  align-items: start;
}

.navbar-brand {
  width: 460px;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.navbar-brand img {
  transition: all 0.1s ease-in;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}

.nav-inside {
  width: 100%;
  padding: 0;
}

#header .header-right a {
  font-size: 15px;
  line-height: 27px;
  color: #000;
  font-family: "Public Sans", sans-serif;
}

header .header-search-btn {
  width: 30px;
  padding: 3px 6px;
}

header .header-search-btn>img {
  width: 100%;
}

#header .header-right a+a {
  margin-top: 6px;
}

.navbar-toggler {
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: 0;
  background: transparent;
  margin: 0;
  z-index: 999;
  margin-left: -13px;
}

button.navbar-toggler:focus {
  box-shadow: none !important;
  outline: none !important;
}

.navbar-default .navbar-toggler:hover,
.navbar-default .navbar-toggler:focus {
  background: transparent;
}

.navbar-toggler:not([class="collapsed"]),
.navbar-toggler:not([class="collapsed"]):focus,
.navbar-toggler:not([class="collapsed"]):hover {
  background: transparent;
}

.navbar-toggler.collapsed,
.navbar-toggler.collapsed:focus,
.navbar-toggler.collapsed:hover {
  background: transparent;
}

.navbar-toggler .navbar-toggler-icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 2px;
  margin-top: 0;
  background: transparent !important;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  vertical-align: middle;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  content: "";
  width: 18px;
  height: 2px;
  position: absolute;
  right: 0;
  transition: all 0.2s ease-out;
  background: var(--black);
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  background: var(--black) !important;
}

.navbar-toggler.collapsed .navbar-toggler-icon:before,
.navbar-toggler.collapsed .navbar-toggler-icon:after {
  background: var(--black);
}

.navbar-toggler.collapsed .navbar-toggler-icon:before {
  top: -6px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}

.navbar-toggler .navbar-toggler-icon:before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.navbar-toggler.collapsed .navbar-toggler-icon:after {
  bottom: -6px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}

.navbar-toggler .navbar-toggler-icon:after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.header-right {
  position: relative;
  z-index: 999;
  margin-top: 10px;
  margin-left: 20px;
}

.navbar-collapse,
.navbar-collapse.collapsing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f5f6f8;
  padding: 0;
  z-index: 9999;
  margin: 0;
  border: 0;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 0;
  display: block !important;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.collapse.show {
  height: 100vh;
}

.navbar-inside {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.post-meta {
  padding: 17px 0 0;
}

.post-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.post-meta-content>span {
  width: 12px;
  height: 10px;
  display: inline-flex;
}

.post-meta-content>span>img {
  width: 100%;
  object-fit: contain;
}

.post-meta-content>h5 {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  padding: 0 27px;
  position: relative;
  background: #fff;
  letter-spacing: 3.21px;
  font-family: var(--font-fgnew);
}

.post-meta-content::before {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: 1.2px;
  background: #000;
  transform: translateX(-50%);
  left: 50%;
}

.post-meta-content>h5::before,
.post-meta-content>h5::after {
  content: "";
  position: absolute;
  height: 7px;
  top: 50%;
  width: 1.5px;
  background: #000;
  transform: translateY(-50%);
}

.post-meta-content>h5::before {
  left: 0;
}

.post-meta-content>h5::after {
  right: 0;
}

/*****************=== HEADER 2 CSS ===*******************/
header#header1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  width: 100%;
  position: fixed;
  background: #fff;
  transform: translateY(-100%);
  left: 0;
  top: 0;
  transition: all 0.3s ease-in;
  z-index: 9999;
}

.fixed-haeder header#header1 {
  transform: translateY(0);
}

.subsc-modal {
  z-index: 999999;
}

#header1 .navbar {
  height: 80px;
  padding: 10px 0;
  align-items: center;
}

#header1 .header-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
}

#header1 .header-logo>img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

#header1 .header-right .btn {}

#header1 .navbar-menu-search-btn {
  margin-top: 0;
}

#header1 .header-right .btn+.btn {}

#header1 .header-right {
  margin-top: 0;
}

#header1 .navbar-toggler {
  margin: -3px;
  line-height: 20px;
  margin-top: -8px;
}

#header1 .navbar-toggler .navbar-toggler-icon {
  width: 30px;
  height: 1px;
}

#header1 .navbar-toggler .navbar-toggler-icon:before,
#header1 .navbar-toggler .navbar-toggler-icon:after {
  width: 30px;
  height: 1px;
}

#header1 .navbar-toggler.collapsed .navbar-toggler-icon:after {
  bottom: -11px;
}

#header1 .navbar-toggler.collapsed .navbar-toggler-icon:before {
  top: -10px;
}

/* ================= header menu Start ================= */
.navbar-menu-head {
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 0.25px solid #bcbdbf;
  width: 100%;
  position: relative;
}

.navbar-menu-right {
  margin-left: 35px;
}

.navbar-menu-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
}

.navbar-menu-logo>img {
  width: 100%;
}

.navbar-menu-head .navbar-toggler {
  margin-left: -3px;
  margin-top: -3px;
}

.navbar-menu-head .navbar-toggler .navbar-toggler-icon {
  width: 30px;
  height: 1px;
}

.navbar-menu-head .navbar-toggler .navbar-toggler-icon:before,
.navbar-menu-head .navbar-toggler .navbar-toggler-icon:after {
  width: 30px;
  height: 1px;
}

.navbar-menu-head .navbar-toggler.collapsed .navbar-toggler-icon:before {
  top: -9px;
}

.navbar-menu-head .navbar-toggler.collapsed .navbar-toggler-icon:after {
  bottom: -9px;
}

.header-menu-body {
  padding: 46px 55px 0;
  height: calc(100vh - 80px);
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

.header-menu-search-box {
  margin-bottom: 40px;
}

.header-menu-search-box .form-control {
  padding-left: 60px;
  background-image: url(../images/search-icon.svg);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 16px;
}

.header-menu-list::before {
  content: "";
  position: absolute;
  left: 50%;
  border: 0;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  height: 1px;
  background-color: #bcbdbf;
  bottom: 0;
}

.header-menu-item {
  width: 33.33%;
  position: relative;
}

.header-menu-list {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 20px;
}

.header-menu-link {
  padding: 24px;
  position: relative;
  padding-left: 145px;
  display: flex;
  align-items: center;
  min-height: 158px;
}

.header-menu-icon {
  position: absolute;
  width: 120px;
  height: 93px;
  text-align: center;
  left: 7px;
  top: 50%;
  padding: 0 14px;
  transform: translateY(-50%);
}

.header-menu-link span {
  font-size: 15px;
  line-height: 18px;
  font-family: var(--font-fgnew);
  letter-spacing: 5px;
  color: var(--black);
  text-transform: uppercase;
}

.header-menu-icon img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.header-menu-link::before {
  content: "";
  position: absolute;
  left: 50%;
  border: 0;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  height: 1px;
  background-color: #bcbdbf;
  bottom: 0;
}

.header-menu-item::after {
  content: "";
  position: absolute;
  top: 50%;
  border: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background-color: #bcbdbf;
  right: 0;
}

.header-menu-item.no-line .header-menu-link::before {
  display: none;
}

.header-menu-item:nth-child(3n + 3)::after {
  opacity: 0;
}

/* .header-menu-item:nth-child(7) .header-menu-link::before,
   li.header-menu-item:nth-child(9) .header-menu-link::before,
   li.header-menu-item:nth-child(8) .header-menu-link::before {
     opacity: 0;
   } */
.header-menu-bottom {
  padding: 16px;
  margin-top: auto;
}

.hmb-icon {
  width: 200px;
}

.hmb-icon>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hmb-text {
  width: calc(100% - 200px);
  padding-left: 52px;
  font-size: 25.5px;
  line-height: 32px;
  font-family: "EditorialNewRegular400";
}

.hmb-text p a {
  text-decoration: underline;
}

.hmb-text p a:hover {
  text-decoration: none;
}

/* ================= Header End ================= */

/***********************************/
.news-post-img {
  width: 100%;
  margin-bottom: 26px;
}

.news-post-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.news-post-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.news-post-content h1 {
  font-size: 111.36px;
  font-family: FixtureCondensedSemiBold;
  line-height: 110px;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.news-post-content h4 {
  font-size: 25px;
  font-family: var(--font-en);
  line-height: 31px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.news-post-meta {
  width: 100%;
}

.news-post-meta {
  font-size: 25px;
  line-height: 30px;
  font-family: EditorialNewItalic;
  color: #000;
  max-width: 707px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 13px;
}

.news-post-save {
  font-size: 21px;
  line-height: 30px;
  font-family: EditorialNewItalic;
  color: #000;
  /* max-width: 622px; */
  margin-bottom: 41px;
  text-align: left;
  margin-bottom: 13px;
}

.news-post-meta span {
  min-width: 120px;
  text-align: center;
}

.read-more-btn {
  font-size: 13px;
  letter-spacing: 0px;
  color: #1f75ba !important;
  line-height: 20px;
  font-family: "Public Sans";
}

.read-more-btn:hover {
  text-decoration: underline;
}

.article-post-by {
  font-size: 25px;
  font-family: "EditorialNewItalic";
  line-height: 30px;
  margin-bottom: 13px;
  color: #000;
  max-width: 622px;
  margin: 0 auto;
  text-align: left;
}

.article-post-by>span {
  font-family: "EditorialNewRegular400";
}

.post-single {
  /*margin-left: 10%;
  margin-right: 10%;*/
}

.article-post-auther-img img {
  width: 110px;
  object-fit: cover;
}

.news-post-content h4 {
  width: 47%;
}

.article-post-single-thumbnail {
  height: auto;
  margin-bottom: 89px;
  width: 100%;
}

.article-post-single-thumbnail img {
  width: 100%;
  height: 507px;
  object-fit: cover;
}

/* mobile view*/
@media screen and (max-width: 767px) {
  .news-post-content h4 {
    margin: 0px 5%;
  }

  .article-post-by {
    font-size: 16px;
  }

  .news-post-content h4 {
    width: 100%;
    margin-bottom: 10px;
  }

  .article-post-single-thumbnail {
    height: auto;
    margin-bottom: 24px;
    width: 100vw;
    margin-left: -24px;
  }

  .article-post-single-thumbnail img {
    height: 213px;
  }
}

@media screen and (max-width: 991.98px) {
  .article-post-by {
    font-size: 16px;
  }
}




/* ================= home popular Start ================= */
.slider-container {
  width: 100%;
  padding: 0 56px 0 0;
  overflow: hidden;
}

.slider-container .slick-list {
  overflow: visible;
}

.popular_slider .slick-next {
  right: -52px;
}

.popular_slider .slick-prev {
  left: -52px;
  display: none !important;
}

.popular_slider .slick-arrow {
  width: 45px;
  height: 246px;
  border-radius: 0;
  top: 0;
  transform: none;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgb(255 255 255 / 90%) !important;
}

.popular_slider .slick-next:before,
.popular_slider .slick-prev:before {
  width: 24px;
  height: 24px;
  background: #fff;
  line-height: 24px;
  opacity: 1;
  position: relative;
  z-index: 9;
}

.grid-list {
  width: calc(100% + 14px);
  margin-left: -7px;
}

.space-more .grid-item {
  padding: 0 20px;
}

/* .grid-list .slick-list { padding: 0 52px; } */
.grid-item {
  padding: 0 7px;
  width: 33.33%;
}

.grid-img {
  height: 245px;
  width: 100%;
  margin-bottom: 16px;
}

.popular_slider .grid-img::before {
  position: absolute;
}

.grid-item.slick-active .grid-img::before {
  opacity: 0;
}

.grid-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-box {
  text-align: center;
}

.grid-box h4 {
  font-size: 34px;
  line-height: 36px;
  margin-bottom: 12px;
  font-family: "PT Serif";
  font-weight: 500;
  letter-spacing: -0.7px;
  min-height: 71px;
}

.grid-box p {
  font-size: 9px;
  font-family: var(--font-fg);
  letter-spacing: 0.3px;
  font-family: var(--font-fgnew);
}

/* ================= home-openion Start ================= */
.home-openion-content blockquote {
  text-align: center;
  font-size: 33.39px;
  line-height: 48px;
  font-family: "EditorialNewItalic";
  letter-spacing: -0.2px;
}

.home-openion-content {
  margin-top: -6px;
}

.post-by {
  font-size: 17px;
  font-family: "EditorialNewItalic";
  line-height: 26px;
  margin-bottom: 0px;
  font-weight: 400;
}

.post-by>span {
  font-family: "EditorialNewRegular400";
}

.home-openion-content .post-by {
  margin-bottom: 20px;
}

/* ================= home-case-update Start ================= */
.hcu-head {
  background: #e1dabd;
  position: relative;
  padding: 14px 20px;
  padding-left: 130px;
  padding-right: 200px;
}

.hcu-logo {
  position: absolute;
  width: 130px;
  left: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 14px 20px;
}

.hcu-logo img {
  width: 60px;
}

.hcu-head h3 {
  font-size: 55px;
  line-height: 55px;
  text-transform: capitalize;
  font-family: "EditorialNewRegular400";
  margin-bottom: 0;
  margin-top: 10px;
  letter-spacing: 0.2px;
}

.hcuh-right {
  font-size: 12px;
  font-family: "FoundryGridnikW03Bold";
  width: 208px;
  text-align: center;
  line-height: 13px;
  position: absolute;
  right: 0;
  letter-spacing: 3.2px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  padding-bottom: 0;
}

.hcu-left {
  width: 50%;
}

.hcu-body {
  display: flex;
  flex-wrap: wrap;
}

.hcu-left h6 {
  font-size: 11px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3.3px;
  margin-bottom: 12px;
}

.hcu-left-upper {
  padding: 28px 33px 36px;
  background: #e1ded7;
  border: 1px solid #fff;
}

.hcu-left h3 {
  font-size: 32px;
  font-family: "PT Serif";
  letter-spacing: -0.5px;
  line-height: 36px;
  margin-bottom: 5px;
}

.hcu-left h5 {
  font-size: 16px;
  margin-left: 3px;
  line-height: 20px;
  font-family: "EditorialNewRegular400";
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.hculm-left,
.hculm-right {
  width: 50%;
  padding: 15px 30px 17px;
}

.hculm-right {
  position: relative;
  padding-right: 100px;
  display: flex;
  align-items: center;
  background: #e0dfe8;
  border: 1px solid #fff;
}

.hculm-icon {
  position: absolute;
  right: 30px;
  top: 42px;
  width: 60px;
}

.hculm-right h3 {
  margin-bottom: 3px;
}

.hculm-left {
  background: #dbe7e5;
  border: 1px solid #fff;
}

.hcu-left-lower {
  padding: 25px 33px 30px;
  background: #ede4dd;
  border: 1px solid #fff;
}

.hcu-right {
  width: 50%;
}

.hcu-right-upper {
  padding: 43px 30px 30px;
  background-color: #dbe7e5;
  border: 1px solid #fff;
}

.hcur-left,
.hcur-right {
  width: 50%;
}

.hcu-right-upper h2 {
  font-size: 84px;
  line-height: 84px;
  display: flex;
  justify-content: start;
  margin-bottom: 0;
}

.hcu-right-upper h2>span {
  font-size: 11px;
  font-family: "PT Serif";
  line-height: 15px;
  padding-left: 8px;
  padding-top: 14px;
}

.hcur-left {
  padding-right: 20px;
}

.hcu-right-upper h4 {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0;
  margin-top: 18px;
  padding-left: 7px;
  letter-spacing: 0.31px;
}

.hcu-right-lower {
  padding: 32px 28px 31px 32px;
  background: #e1ded7;
  border: 1px solid #fff;
  align-items: center;
}

.hcur-text h4 {
  font-size: 24.5px;
  line-height: 29px;
  font-family: "EditorialNewRegular400";
  margin-bottom: 1px;
}

.hcur-text {
  width: 37%;
  padding-right: 14px;
}

.hcur-text p {
  font-size: 14px;
  line-height: 23px;
  font-family: "PT Serif";
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.hcur-graph {
  width: 71%;
  margin-top: 6px;
}

.article-progress-bar {
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #000;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.reading-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: #007bff;
  /* Replace with your desired color */
  z-index: 9999;
  transition: width 0.2s ease;
}

/* ================= essay-series Start ================= */
.essay-series-post-list {
  width: calc(100% + 14px);
  margin-left: -7px;
  display: flex;
  flex-wrap: wrap;
}

.essay-series-post-item {
  padding: 7px;
  flex: 1;
}

.essay-series-post-img {
  position: relative;
  width: 100%;
  padding-bottom: 77%;
}

.essay-series-post-img>img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.essay-series-post-content h4 {
  font-size: 33.39px;
  line-height: 36px;
  margin-bottom: 5px;
  letter-spacing: -0.5px;
}

.essay-series-post-content p {
  display: none;
}

.essay-series-post-content {
  text-align: center;
  padding: 10px 0;
}

.post-date {
  font-size: 10px;
  line-height: 10px;
  font-family: "FoundryGridnikW03Bold";
  color: #000;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.38px;
}

.icon-plus {
  margin-left: 12px;
}

.icon-plus img {
  transition: transform 0.5s ease;
}

.post-date-1 {
  justify-content: left;
}

.essay-series-colunm-2 .essay-series-post-item:first-child {
  width: 50%;
  display: block;
}

.essay-series-colunm-2 .essay-series-post-item:first-child .essay-series-post-img {
  width: 100%;
  padding-bottom: 77%;
}

.essay-series-colunm-2 .essay-series-post-item:first-child .essay-series-post-content {
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.essay-series-colunm-2 .essay-series-post-content p {
  display: block;
}

.email-mag {
  font-style: italic;
}

@media (min-width:767.98px) {
  .essay-series-post-item:first-child {
    width: 100%;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    flex: none;
  }

  .essay-series-post-item:first-child .essay-series-post-img {
    width: calc(100% - 260px);
    padding-bottom: 38.5%;
  }

  .essay-series-post-item:first-child .essay-series-post-content {
    width: 270px;
    text-align: left;
    font-size: 16.7px;
    padding: 16px 0;
    padding-right: 40px;
  }

  .essay-series-post-item:first-child .essay-series-post-content p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-top: 17px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .essay-series-post-item:first-child .essay-series-post-content h4 {
    letter-spacing: -1px;
  }
}

/* ================= essay-of-month Start ================= */
.essay-of-month-container {
  padding-top: 85px;
}

.eom-content {
  padding-right: 20px;
  align-items: center;
}

.eom-img {
  width: 49%;
}

.eom-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.eom-text {
  width: 51%;
  padding-left: 21px;
  font-size: 16px;
  line-height: 25px;
}

.eom-text h4 {
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 3px;
}

.eom-text p {
  margin-bottom: 2px;
}

.eom-card {
  text-align: center;
  background-color: #d5d8d3;
}

.eom-card-head {
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-fgnew);
  letter-spacing: 3.6px;
  padding: 8px;
  background: #b6c0b4;
  text-transform: uppercase;
}

.eom-card-body {
  padding: 22px 22px 14px 22px;
}

.eom-card-body blockquote {
  font-size: 24px;
  line-height: 39px;
  font-family: "EditorialNewItalic";
  margin-bottom: 20px;
}

.eom-card-bottom a {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  font-family: "Public Sans";
  color: #fff;
  background-color: #557958;
  display: block;
  padding: 19px;
}

.eom-card-bottom a:hover {
  background-color: #426f46;
}

/* ================= photo-story Start ================= */
.home-photo-story-img {
  width: 100%;
  display: block;
  margin-bottom: 25px;
}

.home-photo-story-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-photo-story-content h4 {
  font-size: 34px;
  line-height: 36px;
  font-family: "PT Serif";
  letter-spacing: -1px;
  margin-bottom: 14px;
  /*text-transform: uppercase;*/
}

.home-photo-story-content .post-date {
  margin-top: 16px;
}

/* ================= photo-story Start ================= */
.hms-left {
  width: calc(100% - 304px);
  padding-right: 80px;
}

.hms-membership-logo {
  width: 59%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hms-membership-form-content {
  width: 41%;
  padding: 0 18px;
  padding-left: 30px;
  line-height: 22px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.hms-membership-logo img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hms-membership-form-content h3 {
  font-size: 31px;
  line-height: 38px;
  margin-bottom: 15px;
  font-family: var(--font-en);
  text-transform: capitalize;
}

.hms-membership-form .form-group+.form-group {
  margin-top: 13px;
}

.hms-membership-form .form-group .form-control {
  font-size: 14px;
  padding: 4px 8px;
  height: 28px;
  font-weight: 400 !important;
  border: 1px solid #606b75 !important;
  color: #606b75 !important;
}

.hms-membership-form {
  padding-top: 12px;
  margin-top: auto;
}

.hms-right {
  width: 304px;
  display: flex;
  flex-flow: column;
}

.crosword-contetnt {
  margin-bottom: 12px;
}

.crosword-contetnt h5 {
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 9px;
}

.hms-right .btn {
  margin-top: auto;
}

.crosword-img {
  width: 100%;
}

.crosword-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* ================= photo-story Start ================= */
.home-contributor-list {
  padding-top: 30px;
}

.home-contributor-item+.home-contributor-item {
  margin-top: 25px;
}

.home-contributor-left {
  width: calc(100% - 556px);
}

.home-contributor-mid {
  width: 276px;
  text-align: left;
  padding-right: 15px;
  padding-left: 5.3%;
  padding-top: 11px;
}

.home-contributor-right {
  width: 280px;
  padding: 34px 0 10px;
  line-height: 25px;
}

.home-contributor-mid h4 {
  font-size: 55px;
  line-height: 50px;
  font-family: "EditorialNewItalic";
  margin-bottom: 13px;
}

.home-contributor-mid h4>span {
  font-size: 28px;
  line-height: 32px;
  font-family: "PT Serif";
  display: block;
}

.home-contributor-mid h6 {
  font-size: 10px;
  line-height: 11px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 0.3px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.home-contributor-right p {
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.home-contributor-img {
  height: 181px;
}

.home-contributor-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ================= home-podcast Start ================= */
.home-podcast-list {
  width: calc(100% + 16px);
  margin-left: -8px;
  padding-top: 10px;
  display: inline-block;
}

.home-podcast-item {
  padding: 8px;
  width: 33.33%;
  float: left;
  display: inline-block;
}

.home-podcast-box {
  display: block;
  width: 100%;
  position: relative;
  padding-bottom: 75.5%;
}

.home-podcast-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-podcast-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 7px 60px 7px 44px;
  background: rgba(255, 255, 255, 0.8);
}

.home-podcast-caption h4 {
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 0;
  font-family: "PT Serif";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  letter-spacing: -0.3px;
}

.home-podcast-ply-btn {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.home-podcast-ply-btn img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.home-podcast-duration {
  position: absolute;
  font-size: 16px;
  font-family: "Public Sans";
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-weight: 300;
}

@media (min-width:767.98px) {
  .home-podcast-item:nth-child(2) {
    width: 66.66%;
    float: right;
  }

  .home-podcast-item:nth-child(2) .home-podcast-box {
    width: 100%;
  }

  .home-podcast-item:nth-child(2) .home-podcast-caption {
    padding: 20px 98px 20px 95px;
  }

  .home-podcast-item:nth-child(2) .home-podcast-caption h4 {
    font-size: 37px;
    line-height: 44px;
  }

  .home-podcast-item:nth-child(2) .home-podcast-ply-btn {
    width: 54px;
    height: 54px;
    left: 17px;
  }

  .home-podcast-item:nth-child(2) .home-podcast-duration {
    right: 28px;
  }
}

.home-newsletter-form-content h5 {
  font-size: 19px;
  line-height: 26px;
}

.home-newsletter-form .form-group .btn {
  height: 46px;
}

/* ================= CTA Start ================= */
.home .cta-normal-content {
  padding: 56px 0;
}

.cta-normal-content {
  padding: 26px 0;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
  border: 0.24px solid #040505;
  border-left: 0;
  border-right: 0;
}

.cta-normal-content p span {
  font-style: italic;
}

.cta-normal-content p a {
  color: #1f75ba !important;
}

.cta-container .container {
  max-width: 1374px;
}

.cta-content {
  background-image: url(../images/cta-bg.svg);
  padding: 50px 66px;
  min-height: 239px;
  align-items: center;
  justify-content: space-around;
}

.cta-left {
  width: 340px;
  text-align: left;
  /* margin-right: 57px; */
}

.cta-left h3 {
  font-size: 44px;
  line-height: 50px;
  font-family: "EditorialNewRegular400";
  margin-bottom: 0;
}

.cta-right {
  width: 308px;
}

.cta-mid {
  width: 138px;
  text-align: left;
  padding: 0 20px;
}

.cta-mid .form-group+.form-group {
  margin-top: 9px;
}

.cta-right .form-group+.form-group {
  margin-top: 13px;
}

.cta-right .form-group .form-control {
  height: 28px;
  font-size: 14px;
  padding: 4px 6px;
  letter-spacing: 0.5px;
}

/* ================= CTA End ================= */

/* ================= Article Start ================= */
.filter-content {
  justify-content: space-between;
  align-items: end;
  padding-bottom: 8px;
  border-bottom: 1px solid #040505;
}

.filter-content h5 {
  font-size: 15px;
  font-family: "Public Sans";
  margin-bottom: 0;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 300;
}

.filter-box {
  font-size: 15px;
  font-family: "Public Sans";
  line-height: 26px;
}

.filter-box>span {
  font-weight: 300;
  margin-right: 8px;
}

.filter-box>select {
  border: none;
  appearance: none;
  padding: 0px 11px;
  font-weight: 500;
  padding-right: 16px;
  cursor: pointer;
  background-image: url(../images/arrow-down.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 2px);
  line-height: 26px;
  text-transform: capitalize;
}

.custom-dropdown .btn {
  font-size: 15px;
  line-height: 20px;
  padding: 4px;
  min-width: auto;
  color: var(--black);
  letter-spacing: 0.3px;
  text-transform: inherit;
}

.custom-dropdown+.custom-dropdown {
  margin-left: 14px;
}

.dropdown-menu,
.dropdown-menu[style] {
  padding: 4px 0;
  border: 1px solid;
  border-radius: 0;
  position: absolute !important;
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  min-width: 110px;
}

.dropdown-item {
  font-size: 15px;
  padding: 5px 8px;
  line-height: 14px;
  font-family: "Public Sans";
  font-weight: 300;
  background: transparent !important;
  color: #040505 !important;
}

.article-text {
  width: calc(100% - 295px);
  padding-right: 50px;
}

.article-item {
  padding: 46px 40px 40px 40px;
}

.article-box {
  align-items: center;
}

.article-item+.article-item {
  border-top: 1px solid #040505;
}

.breadcrumb {
  margin-left: -1px;
  margin-bottom: 16px;
}

.breadcrumb li+li::before {
  content: "|" !important;
  font-size: 17px;
  font-weight: 300;
  padding: 0 9px 0 6px;
}

.breadcrumb li {
  font-size: 14px;
  line-height: 16px;
  font-family: "FoundryGridnikW03Bold";
  text-transform: uppercase;
  letter-spacing: 3.6px;
  color: #040505 !important;
  display: flex;
  align-items: center;
}

.article-text h3 {
  font-size: 33px;
  margin-bottom: 16px;
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-text p {
  margin-bottom: 14px;
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-text .post-by {
  font-size: 19px;
  line-height: 29px;
}

.article-thumbnail {
  width: 295px;
  padding: 5px 0;
}

.article-thumbnail a {
  width: 100%;
  display: block;
}

.article-thumbnail img,
.article-thumbnail a img {
  height: 228px;
  width: 295px;
  object-fit: cover;
  transition: all 0.3s ease-in;
}

/* .article-thumbnail img,
  .article-thumbnail a:hover img {
    filter: grayscale(1);
  } */
.load-more a {
  font-family: "Public Sans";
  color: #0064ad;
  display: block;
  padding: 27px 0;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.25px;
  border: 1px solid #040505;
  border-left: 0;
  border-right: 0;
}

.podcast-list .post-date,
.article-list .post-date {
  align-items: left;
  justify-content: flex-start;
}

/* ================= Article End ================= */

/* ================= most interested -link Start ================= */
.most-interest-heading {
  text-align: center;
  margin-bottom: 37px;
}

.most-interest-heading h3 {
  font-size: 34px;
  line-height: 40px;
  font-family: var(--font-en);
  margin-top: 50px;
  margin-bottom: 0;
  letter-spacing: -0.2px;
  font-weight: 400;
}

.most-interest-container {
  margin-bottom: 80px;
}

.most-interest-list {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.most-interest-item {
  width: 30%;
  padding: 0px 0px;
  margin: 16px;
}

.most-interest-link {
  position: relative;
  padding-left: 122px;
  display: flex;
  align-items: center;
  min-height: 122px;
}

.most-interest-icon {
  position: absolute;
  width: 110px;
  height: 86px;
  text-align: center;
  left: 7px;
  top: 50%;
  padding: 0 1px;
  transform: translateY(-50%);
}

.most-interest-link span {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-fgnew);
  letter-spacing: 3px;
  color: var(--black);
  text-transform: uppercase;
}

.most-interest-icon img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.most-interest-link::before {
  content: "";
  position: absolute;
  left: 50%;
  border: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 0.24px;
  background-color: #040505;
  bottom: 0;
}

.most-interest-link.last-row::before {
  content: "";
  position: absolute;
  left: 50%;
  border: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: #fff;
  bottom: 0;
}

.most-interest-item.no-line .most-interest-link::before {
  display: none;
}

/* ================= most interested -link End ================= */

/* ============================================== ABOUt PAGE START ============================================== */
.page-link-container {
  padding: 38px 0;
}

.page-links {
  justify-content: space-between;
}

.page-links li {
  padding: 4px 10px;
}

.page-link {
  font-size: 14px;
  line-height: 16px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 4px;
  color: #000 !important;
  box-shadow: none !important;
  padding: 5px 0 3px;
  border: none;
  position: relative;
  background: transparent !important;
}

.page-link::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 0;
  height: 2px;
  background: #040505;
  bottom: 0px;
  transition: all 0.3s ease-in;
}

.page-links li:hover .page-link::before,
.page-links li.active .page-link::before {
  width: 100%;
  left: 0;
}

.about-logo {
  position: relative;
  text-align: center;
  max-height: 560px;
  margin-bottom: 78px;
}

.about-logo img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.other-author-item+.other-author-item {
  margin-top: 30px;
}

.other-author-box {
  align-items: center;
}

.other-author-img {
  width: calc(100% - 429px);
  height: 227px;
}

.other-author-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.other-author-content {
  width: 429px;
  padding-left: 66px;
}

.other-author-content h3 {
  font-size: 40px;
  line-height: 58px;
  font-family: "EditorialNewItalic";
  letter-spacing: -0.2px;
  margin-bottom: 0px;
}

.other-author-content h5 {
  font-size: 27px;
  font-family: "PT Serif";
  line-height: 26px;
  margin-bottom: 16px;
}

.other-author-content .read-more-btn {
  font-size: 12px;
}

.other-author-content p {
  margin-bottom: 10px;
}

/* ============================================== ABOUt PAGE END ============================================== */

/* ============================================== CONTACT PAGE START ============================================== */
.contact-list li {
  text-align: center;
  font-size: 24px;
  font-family: "PT Serif";
  letter-spacing: -0.1px;
}

.contact-list li+li {
  margin-top: 45px;
}

.contact-list li span,
.contact-list li a {
  display: block;
  font-size: 34px;
  line-height: 43px;
  font-family: "EditorialNewItalic";
  letter-spacing: 0.4px;
  margin-top: 16px;
  color: var(--black);
}

/* ============================================== CONTACT PAGE END ============================================== */

/* ============================================== SEARCH PAGE START ============================================== */
.search-bar {
  position: relative;
  margin-bottom: 14px;
}

.search-bar .form-control {
  padding-right: 60px;
}

.search-bar .search-bar-btn {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 46px;
  right: 0;
  padding: 10px 10px 14px 10px;
}

.search-bar .search-bar-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 19px;
}

/* ============================================== SEARCH PAGE END ============================================== *
   
   /* ============================================== author PAGE START ============================================== */
.author-content {
  width: 100%;
  padding: 0 45px;
}

.auther-img {
  width: 140px;
}

.author-name {
  width: calc(100% - 377px);
  padding-left: 42px;
  font-size: 17px;
  line-height: 19px;
  padding-top: 16px;
}

.auther-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-name h3 {
  font-size: 68px;
  line-height: 70px;
  margin-bottom: 12px;
}

.author-name h6 {
  font-size: 15px;
  line-height: 22px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 0.1px;
  margin-bottom: 6px;
}

.author-name blockquote {
  font-size: 24px;
  line-height: 28px;
  font-family: "PT Serif";
  letter-spacing: 0.4px;
  margin-bottom: 20px;
}

.share-box h5 {
  font-size: 14px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 1.1px;
  text-transform: uppercase;
  line-height: 21px;
  margin-bottom: 14px;
}

.share-box .share-post-list li a {
  width: 34px;
  height: 35px;
  line-height: 34px;
}

/*circluar floating button with black background and whihte foreground*/
.floating-btn {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 1000;
  background: #000;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.essayMode {
  z-index: 20000;
  background: #fff;
  color: #000;
}

/*Move floating buttoon to bottom center is mobile view*/
@media (max-width: 767.98px) {
  .floating-btn {
    bottom: 20px;
    top: auto;
    right: 50%;
    transform: translateX(50%);
  }
}

/* ============================================== author PAGE END ============================================== */

/* ============================================== podcast_video START ============================================== */
.category-tab-list {
  max-width: 700px;
  margin: 50px auto;
}

.category-tab-list li {
  padding: 4px 10px 0 0;
  width: 33.333%;
}

.category-tab-link {
  font-size: 13px;
  line-height: 20px;
  font-family: "Public Sans";
  font-weight: 300;
  letter-spacing: 0.1px;
  position: relative;
}

.category-tab-link::before {
  content: "";
  position: absolute;
  width: 0;
  left: 50%;
  height: 1px;
  background: var(--black);
  bottom: 0px;
  transition: all 0.3s ease-in;
}

.category-tab-list li:hover .category-tab-link::before,
.category-tab-list li.active .category-tab-link::before {
  left: 0;
  width: 100%;
}

/* ============================================== podcast_video END ============================================== */

/* ============================================== NEWS SINGLE START ============================================== */
.post-category-heading {
  text-align: center;
  margin-top: 41px;
  font-size: 11px;
  line-height: 12px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3.5px;
  margin-bottom: 40px !important;
}

.pch-img {
  display: inline-block;
  height: 90px;
  margin-bottom: 23px;
}

.pch-img img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.pch-img p {
  font-size: 11.13px;
}

.post-single-thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 89px;
}

.block-text {
  font-size: 21px;
  line-height: 33px;
  letter-spacing: 0.5px;
}

.block-text p:not(:last-child) {
  margin-bottom: 30px !important;
}

.block-text h4 {
  font-size: 34px;
  margin-bottom: 20px;
  line-height: 40px;
  font-family: "EditorialNewItalic";
}

.post-single-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-text>p:first-child>span,
.block-text span.drop-cap {
  font-size: 112px;
  line-height: 98px;
  display: inline-block;
  margin-left: -4px;
  float: left;
  margin-right: 12px;
  text-transform: uppercase;
}

.block-text>p:first-child>span img {
  width: 95%;
  vertical-align: top;
}

.block-text p:last-child {
  margin-bottom: 0;
}

blockquote {
  font-size: 35px;
  line-height: 49px;
  font-family: "EditorialNewItalic";
  padding-top: 5px;
  margin-bottom: 33px;
}

.post-single-img-50 {
  float: right;
  width: 50%;
  padding: 5px 0 0 45px;
  margin-bottom: 11px;
}

.post-single figcaption {
  font-size: 11px;
  line-height: 14px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3px;
  width: 80%;
  display: inline-block;
  margin-top: 13px;
  text-transform: uppercase;
  clear: both;
  display: block;
  margin: 13px auto;
  text-align: center;
}

.post-single-thumbnail figcaption {
  font-size: 11px;
  line-height: 14px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3px;
  width: 80%;
  display: inline-block;
  margin-top: 13px;
  text-transform: uppercase;
  clear: both;
  display: block;
  margin: 13px auto;
  text-align: center;
}

.post-single-img-50 figcaption {
  float: right;
}

.cta-blog {
  margin: 57px 0;
  padding: 30px 0;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.25px;
  border: 1px solid rgb(0 0 0 / 50%);
  border-left: 0;
  border-right: 0;
}

.cta-blog p {
  margin-bottom: 0 !important;
}

.cta-blog p span {
  font-style: italic;
}

.cta-blog p a {
  color: #1f75ba !important;
}

.post-single-img-100 {
  margin-top: 60px;
}

.post-single-img-100 figcaption {
  width: 50%;
  text-align: left;
}

.post-single-img-100 figcaption+figcaption {
  float: right;
  text-align: right;
}

.end-section {
  margin-bottom: 0px;
  position: relative;
  display: flex;
  justify-content: center;
}

.end-section::before {
  content: "";
  position: absolute;
  width: 100%;
  background: #000;
  height: 1px;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.end-section h5 {
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 0;
  padding: 0 51px;
  position: relative;
  background: #fff;
  letter-spacing: 4.21px;
  font-family: var(--font-fg);
  display: inline-block;
  text-transform: uppercase;
}

.end-section h5>span {
  position: absolute;
  width: 13px;
  top: 48%;
  transform: translateY(-50%);
  height: 14px;
}

.end-section h5>span>img {
  width: 100%;
  vertical-align: middle;
}

.end-section h5>span.left {
  left: -2px;
}

.end-section h5>span.right {
  right: -2px;
}

.author-single-container .post-auther-img {
  width: 377px;
}

.post-author-container h5 {
  font-size: 13px;
  line-height: 14px;
  font-family: "FoundryGridnikW03Bold";
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 27px;
}

.post-author-content {
  width: 50%;
}

.post-auther {
  display: flex;
  padding-right: 3%;
}

.post-auther-img {
  /*width: 110px;*/
}

.post-author-name {
  width: calc(100% - 140px);
  padding-left: 28px;
  font-size: 17px;
  line-height: 19px;
}

.post-auther-img img {
  width: 142px;
  height: 178px;
  object-fit: cover;
}

.post-author-name h3 {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 8px;
}

.post-author-name h6 {
  font-size: 11px;
  line-height: 12px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 0.1px;
  margin-bottom: 8px;
}

.share-post {
  width: 50%;
}

.share-post .share-post-list {
  justify-content: flex-end;
}

.share-post-list.fixed {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
  margin-right: 570px;
}

.share-post-list.fixed li+li {
  margin-top: 9px;
  margin-left: 0;
}

.share-post-list li+li {
  margin-left: 7px;
}

.share-post-list li a {
  width: 42px;
  height: 42px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
}

.share-post-list li a:hover {
  background: #333;
}

/* ============================================== NEWS SINGLE END ============================================== */

/* ============================================== PODACAST START ============================================== */
.video-list {
  margin-bottom: 43px;
}

.video-list .video-item {
  margin-top: 43px;
}

.video-content {
  width: 60%;
  padding: 64px 56px 40px;
  position: relative;
  letter-spacing: 0.2px;
}

.video-content .video-cat {
  position: absolute;
  left: 14px;
  top: 12px;
  font-size: 14px;
  font-family: "FoundryGridnikW03Bold";
  text-transform: uppercase;
  letter-spacing: 3.8px;
}

.video-content h3 {
  font-size: 34px;
  position: relative;
  padding-left: 74px;
  line-height: 38px;
  margin-bottom: 17px;
  letter-spacing: normal;
}

.video-content h3 .video-play-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -5px;
  top: 10px;
}

.video-content h3 .video-play-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-content .video-link {
  font-size: 9px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 1px;
  line-height: 12px;
}

.video-content p {
  margin-bottom: 7px;
}

.video-thumbnail {
  width: 40%;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ============================================== PODACAST END ============================================== */
.podcast-text {
  width: calc(100% - 295px);
  padding-left: 50px;
}

.podcast-item {
  padding: 46px 30px 46px 40px;
}

.podcast-item+.podcast-item {
  border-top: 1px solid #040505;
}

.podcast-text .breadcrumb {
  margin-left: -1px;
}

.podcast-text .breadcrumb li+li::before {
  content: "|";
  font-size: 17px;
  font-weight: 300;
  padding: 0 9px 0 6px;
}

.podcast-text .breadcrumb li {
  font-size: 14px;
  line-height: 16px;
  font-family: "FoundryGridnikW03Bold";
  text-transform: uppercase;
  letter-spacing: 3.6px;
  color: #040505 !important;
}

.podcast-text h3 {
  font-size: 33px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
  padding-right: 100px;
}

.podcast-text p {
  margin-bottom: 14px;
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.podcast-text .post-by {
  font-size: 19px;
  line-height: 29px;
}

.podcast-thumbnail {
  width: 295px;
  padding: 5px 0;
  position: relative;
}

.podcast-thumbnail .podcast-ply-btn {
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  bottom: 29px;
  left: 24px;
}

.podcast-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.podcast-duration {
  position: absolute;
  right: -1px;
  font-size: 19px;
  line-height: 22px;
  font-family: "EditorialNewRegular400";
  letter-spacing: 0px;
  top: 45px;
}

.podcast-box {
  position: relative;
}

/* ============================================== CROSSWORD SINGLE START ============================================== */
.cross-theme-content {
  width: 100%;
  height: 500px;
  overflow-x: auto;
}

.cross-theme-content .cross-theme-box {
  width: 50%;
  padding-right: 35px;
}

.crossword-theme-img img {
  width: 100%;
}

.cross-theme-content .cross-theme-box h4 {
  font-size: 32px;
  line-height: 35px;
  font-family: "PT Serif";
  margin-bottom: 16px;
  font-weight: 400;
}

.cross-theme-text {
  font-size: 15px;
  line-height: 20px;
  font-family: "PT Serif";
  letter-spacing: 0.3px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #040505;
}

/* ============================================== CROSSWORD PAGE START ============================================== */
.crossword-list {
  margin-bottom: 43px;
}

.crossword-list .crossword-item {
  margin-top: 43px;
}

.crossword-box {
  position: relative;
  align-items: center;
  color: var(--black) !important;
}

.crossword-content {
  width: 65%;
  letter-spacing: 0.2px;
  padding: 40px 56px 25px;
}

.crossword-content .crossword-cat {
  position: absolute;
  left: 14px;
  top: 12px;
  font-size: 14px;
  font-family: "FoundryGridnikW03Bold";
  text-transform: uppercase;
  letter-spacing: 3.8px;
}

.crossword-content h3 {
  font-size: 34px;
  position: relative;
  line-height: 38px;
  margin-bottom: 13px;
  letter-spacing: normal;
}

.crossword-content h3 .crossword-play-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -5px;
  top: 10px;
}

.crossword-content h3 .crossword-play-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.crossword-content .crossword-link {
  font-size: 9px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 1px;
  line-height: 12px;
}

.crossword-content p {
  margin-bottom: 7px;
}

.crossword-thumbnail {
  width: 26%;
}

.crossword-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crossword_slider .slick-next {
  right: 0;
}

.crossword_slider .slick-prev {
  left: 0;
}

.crossword_slider .slick-arrow {
  width: 45px;
  height: 58%;
  border-radius: 0;
  top: 0;
  transform: none;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgb(255 255 255 / 90%) !important;
  box-shadow: none;
}

.crossword_slider .slick-next:before,
.crossword_slider .slick-prev:before {
  width: 24px;
  height: 24px;
  background: #fff;
  line-height: 24px;
  opacity: 1;
  position: relative;
  z-index: 9;
}

.crossword-slide-list {
  width: calc(100% + 14px);
  margin-left: -7px;
}

.crossword-slide-list .slick-list {
  padding: 0 64px;
}

.crossword-slide-list .slick-list {
  padding: 0px 70px;
}

.crossword-slide-item {
  padding: 0 25px;
  width: 33.33%;
}

.crossword-slide-img {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 100%;
}

.crossword-slide-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.crossword-slide-box {
  text-align: center;
}

.crossword-slide-box h4 {
  font-size: 33px;
  line-height: 35px;
  margin-bottom: 10px;
  font-family: "PT Serif";
  font-weight: 500;
  letter-spacing: -0.7px;
}

.crossword-slide-box p {
  font-size: 9px;
  font-family: var(--font-fg);
  letter-spacing: 0.2px;
}

/* ============================================== CROSSWORD PAGE END ============================================== */

/* ============================================== video PAGE END ============================================== */
.video-block iframe {
  width: 100%;
  height: 100%;
}

.video-block {
  margin-bottom: 65px;
}

.fixed-block-text-content {
  padding: 26px 15px 26px 26px;
  border: 1px solid;
  font-size: 24px;
}

.fixed-block-text {
  padding-right: 80px;
  line-height: 32px;
  font-style: italic;
  height: 600px;
  overflow-y: auto;
}

.fixed-block-text p {
  margin-bottom: 33px;
}

.fixed-block-text p:last-child {
  margin-bottom: 0;
}

/* ============================================== video PAGE END ============================================== */

/* ============================================== podcast PAGE END ============================================== */
.podcast-hero-img {
  max-width: 554px;
  margin: 0 auto;
}

.podcast-hero-img img {
  width: 100%;
  object-fit: contain;
}

.podcast-plyr-player {
  width: 100%;
  margin-bottom: 36px;
}

.podcast-plyr-item h3,
.podcast-plyr-item h3 a {
  font-size: 33px;
  line-height: 40px;
  font-family: "PT Serif";
  margin-bottom: 0;
  position: relative;
  padding-right: 100px;
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--black) !important;
}

.podcast-plyr-item .breadcrumb {
  margin-bottom: 14px;
}

.podcast-plyr-duration {
  position: absolute;
  right: 0;
  font-size: 19px;
  line-height: 20px;
  font-family: "EditorialNewRegular400";
  letter-spacing: -0.2px;
  bottom: 4px;
}

.podcast-plyr-item {
  padding: 31px 24px;
  border-top: 1px solid var(--black);
}

/* ============================================== podcast PAGE END ============================================== */

/* ============================================== newsletter PAGE END ============================================== */
.newsletter-list {
  margin-bottom: 44px;
}

.newsletter-item+.newsletter-item {
  margin-top: 68px;
}

.newsletter-left {
  width: 20%;
  display: flex;
  flex-flow: column;
}

.newsletter-left img {
  width: 100%;
  height: 140px;
}

.newsletter-mid {
  width: 410px;
  text-align: center;
  border-left: 1px solid var(--black);
  display: flex;
  flex-flow: column;
}

.newsletter-title {
  font-family: FixtureCondensedSemiBold;
  padding: 20px;
  min-height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-title h2 {
  font-size: 143px;
  line-height: 124px;
  margin-bottom: 20px;
}

.newsletter-title h3 {
  font-size: 124px;
  line-height: 130px;
  margin-bottom: 4px;
}

.newsletter-title h4 {
  font-size: 92px;
  line-height: 78px;
  margin-bottom: 0;
}

.newsletter-right {
  width: calc(80% - 410px);
  border-left: 1px solid var(--black);
  display: flex;
  flex-flow: column;
}

.newsletter-text {
  padding: 40px 45px 34px;
  font-size: 15px;
  line-height: 19px;
  font-style: italic;
  min-height: 190px;
  display: flex;
  align-items: center;
}

.newsletter-icon {
  padding: 33px;
  padding-bottom: 16px;
}

.newsletter-cat {
  min-height: 84px;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PT Serif";
  font-weight: 700;
  padding: 14px;
  border-top: 1px solid var(--black);
  margin-top: auto;
}

.newsletter-weekend {
  min-height: 84px;
  display: flex;
  align-items: center;
  padding: 14px;
  padding-right: 33px;
  justify-content: center;
  font-size: 19px;
  font-family: "EditorialNewItalic";
  border-top: 1px solid var(--black);
  margin-top: auto;
}

.newsletter-link {
  display: flex;
  align-items: center;
  min-height: 84px;
  justify-content: space-around;
  padding: 20px 42px 10px 42px;
  border-top: 1px solid var(--black);
  margin-top: auto;
}

.newsletter-form {
  width: 100%;
  display: flex;
}

.newsletter-form .form-control {
  border: 1px solid #606b75 !important;
  height: 30px;
  background: #fff !important;
  color: #606b75;
  padding: 4px 10px;
  font-size: 15px;
}

.newsletter-form .btn {
  min-width: 110px;
  font-size: 15px !important;
  line-height: 15px !important;
  height: 30px;
  padding: 6px;
}

/* ============================================== newsletter PAGE END ============================================== */

/* ============================================== photo-story PAGE END ============================================== */
.photo-story-full-list {
  border-top: 1px solid var(--black);
  margin-top: 52px;
}

.photo-story-full-item {
  margin-top: 52px;
  font-size: 23px;
  line-height: 33px;
  letter-spacing: 0.5px;
}

.photo-story-full-img {
  width: 100%;
  margin-bottom: 52px;
}

.photo-story-full-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ============================================== photo-story PAGE END ============================================== */

/* ============================================== subscription PAGE END ============================================== */
.subscription-intro h4 {
  font-size: 35px;
  line-height: 48px;
  font-family: "EditorialNewItalic";
  margin-bottom: 8px;
  letter-spacing: 0;
}

.subscription-intro p {
  margin-bottom: 40px;
}

.subscription-intro p:last-child {
  margin-bottom: 0px;
}

.subscription-intro {
  letter-spacing: 0.24px;
}

.subscription-list {
  width: calc(100% + 52px);
  margin-left: -26px;
}

.subscription-item {
  width: 100%;
  padding: 26px;
}

.subscription-item h3 {
  font-size: 1.2rem;
  line-height: 30px;
  font-family: "PT Serif";
  margin-bottom: 0px;
  letter-spacing: -1px;
  padding-right: 14px;
  margin-top: 5px;
}

.subscription-item h4 {
  font-size: 0.8rem;
  line-height: 24px;
  font-family: "EditorialNewItalic";
  margin-bottom: 5px;
}

.subscription-item sub {
  font-size: .45em;
  bottom: 0
}

.subscription-box {
  border: 1px solid var(--black);
  outline: 1px solid var(--black);
  outline-offset: -9px;
  padding: 43px 55px 43px;
  position: relative;
  overflow: hidden;
  font-size: 19px;
  line-height: 26px;
  display: flex;
  flex-flow: column;
}

.subscription-box h3 {
  font-size: 45px;
  line-height: 81px;
  font-family: "PT Serif";
  margin-bottom: 8px;
  letter-spacing: -1px;
  padding-right: 14px;
}

.subscription-box h4 {
  font-size: 33px;
  line-height: 54px;
  font-family: "EditorialNewItalic";
  margin-bottom: 5px;
}

.subscription-box h4 sub {
  font-size: 19px;
  line-height: 22px;
  display: inline-block;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 2px;
  margin-left: 4px;
  vertical-align: middle;
}

.subscription-box .btn {
  margin-top: 9px;
  font-size: 24px;
  line-height: 29px;
  margin-top: auto;
}

.subscription-box .subscription-i {
  position: absolute;
  width: 31px;
  height: 31px;
  top: 18px;
  right: 18px;
  z-index: 99;
}

.subscription-box .subscription-i img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.subscription-box .recommend {
  position: absolute;
  color: #fff;
  width: 170px;
  height: 166px;
  top: 0;
  right: 0;
}

.subscription-box .recommend span {
  background: var(--black);
  transform: rotate(45deg);
  display: block;
  transform-origin: top left;
  width: calc(100% + 100px);
  position: absolute;
  text-align: center;
  font-size: 11px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3px;
  top: -36px;
  left: 15px;
  padding: 4px;
}

/* ============================================== subscription PAGE END ============================================== */

/* ============================================== profile- PAGE END ============================================== */
.profile-content {
  align-items: center;
}

.profile-img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-deatail {
  width: calc(100% - 228px);
  padding: 20px 0;
  padding-left: 36px;
}

.profile-deatail h6 {
  font-size: 15px;
  line-height: 14px;
  margin-bottom: 9px;
  position: relative;
  background: #fff;
  letter-spacing: 5px;
  font-family: var(--font-fg);
}

.profile-deatail h3 {
  font-size: 67px;
  line-height: 77px;
  font-family: "PT Serif";
  margin-bottom: 9px;
}

.profile-deatail p,
.profile-deatail p a {
  font-size: 26px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: var(--black);
}

.subscription-tabs-content {
  padding: 50px 0;
  border-top: 1px solid var(--black);
  justify-content: space-evenly;
}

.subscription-tabs {
  max-width: 600px;
  margin: 0 auto;
}

.subscription-tabs li {
  padding: 0 15px;
}

.subscription-tabs-link {
  font-size: 14px;
  line-height: 16px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3.7px;
  padding-bottom: 4px;
  position: relative;
}

.subscription-tabs-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background: var(--black);
  bottom: 0;
  left: 50%;
  transition: all 0.3s ease-in;
}

.subscription-tabs-link:hover::before,
.subscription-tabs li.active .subscription-tabs-link::before {
  width: 100%;
  left: 0;
}

.tab-subscription-box {
  padding: 45px 53px 52px;
  border: 1px solid var(--black);
}

.subscription-heading h3 {
  font-size: 52px;
  line-height: 71px;
  font-family: EditorialNewItalic;
  margin-bottom: 0;
}

.subscription-heading {
  margin-bottom: 23px;
}

.subscription-heading h3+p {
  font-size: 25px;
  line-height: 30px;
  margin-top: 10px;
}

.active-tab-subscription {
  max-width: 533px;
  padding-left: 6px;
}

.ats-plane-box {
  margin-bottom: 33px;
}

.ats-plane {
  padding-left: 66px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ats-icon {
  position: absolute;
  width: 44px;
  left: 0;
  top: 5px;
}

.ats-plane h4 {
  font-size: 43px;
  line-height: 45px;
  margin-bottom: 0;
  letter-spacing: -0.2px;
  margin-right: 30px;
}

.ats-plane h4 span {
  display: flex;
  font-size: 15px;
  line-height: 19px;
  font-style: italic;
  margin-top: 8px;
}

.ats-plane h5 {
  font-size: 36px;
  line-height: 37px;
  margin-bottom: 0;
  font-family: "EditorialNewItalic";
  letter-spacing: 0.5px;
  text-align: right;
}

.ats-plane h5 span {
  display: block;
  font-size: 14px;
  font-family: "FoundryGridnikW03Bold";
  font-style: normal;
  line-height: 21px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.active-tab-subscription .btn {
  margin-bottom: 33px;
}

.active-tab-subscription p {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  color: #0064ad !important;
  margin-bottom: 11px;
}

.active-tab-subscription p:last-child {
  margin-bottom: 0;
}

.active-tab-subscription p a {
  color: #0064ad !important;
}

.inactive-tab-subscription {
  max-width: 706px;
}

.its-plane-item {
  border: 1px solid var(--black);
  overflow: hidden;
}

.its-plane h3 {
  font-size: 43px;
  line-height: 45px;
  margin-bottom: 0;
  letter-spacing: -0.2px;
  margin-right: 30px;
}

.its-plane h3 span {
  display: flex;
  font-size: 15px;
  line-height: 19px;
  font-style: italic;
  margin-top: 8px;
}

.its-plane h5 {
  font-size: 36px;
  line-height: 37px;
  margin-bottom: 0;
  font-family: "EditorialNewItalic";
  letter-spacing: 0.5px;
  text-align: right;
}

.its-plane h5 span {
  display: block;
  font-size: 14px;
  font-family: "FoundryGridnikW03Bold";
  font-style: normal;
  line-height: 23px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.its-plane {
  padding: 22px 57px 24px 62px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.its-plane-item+.its-plane-item {
  border-top: 0;
}

.its-recommemd {
  position: absolute;
  color: #fff;
  width: 120px;
  height: 100%;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  z-index: -1;
}

.its-recommemd span {
  background: var(--black);
  transform: rotate(-90deg);
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  text-align: center;
  font-size: 8px;
  font-family: "FoundryGridnikW03Bold";
  letter-spacing: 3px;
  padding: 0 4px 0;
  left: -35px;
  border-bottom: 1px solid #fff;
}

/* ============================================== profile- PAGE END ============================================== */

/* ============================================== Phot story page 3 PAGE END ============================================== */
.story-slide-container {
  display: flex;
  flex-wrap: wrap;
  background: var(--black);
}

.story-img-slider-content {
  width: calc(100% - 450px);
  padding: 100px 0;
}

.story-thumb-slider-content {
  width: 450px;
  padding: 30px 26px;
}

.story-thumb-slider {
  max-width: 262px;
}

.story-img-slider {
  padding-bottom: 0;
}

.story-thumb-slider .slick-prev,
.story-thumb-slider .slick-next {
  top: 50%;
}

.story-img-slide-img {
  position: relative;
  width: 100%;
  padding-bottom: 66.5%;
}

.story-img-slide-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.story-img-slide-arrow {
  padding: 45px 20px 0px;
  text-align: center;
}

.story-img-slide-text {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-family: "PT Serif";
  padding: 0 6px;
  padding-top: 34px;
  text-align: center;
}

.story-thumb-slider-img {
  width: 100%;
  position: relative;
  padding-bottom: 68.4%;
  transition: all 0.3s ease-in;
  border: 1px solid transparent;
}

.slick-current .story-thumb-slider-img {
  border: 1px solid #fff;
}

.story-thumb-slider-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.story-img-slide-arrow-left,
.story-img-slide-arrow-right {
  width: 24px;
  height: 24px;
  background: #727272;
  border-radius: 12px;
  line-height: 20px;
  text-align: center;
  margin: 0 10px;
  padding: 7px;
}

.story-img-slide-arrow-left img,
.story-img-slide-arrow-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 6px;
  margin: 0 auto;
}

/* ============================================== Phot story page 3 PAGE END ============================================== */

/* ================= Footer Start ================= */
footer {
  padding-bottom: 40px;
}

.footer-content {
  border-top: 1px solid #000;
  padding-top: 80px;
  align-items: center;
  margin-bottom: 60px;
}

.footer-left {
  width: 120px;
}

.footer-logo {
  width: 100%;
  margin-top: -15%;
}

.footer-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.footer-mid {
  width: calc(100% - 346px);
  padding: 0 15px;
  justify-content: space-evenly;
}

.footer-box+.footer-box {
  margin-left: 80px;
}

.footer-nav-links {
  padding-right: 6%;
}

.footer-nav-links li {
  font-size: 14px;
  line-height: 14px;
  font-family: "Public Sans Medium";
  font-weight: 500;
}

.footer-nav-links li+li {
  margin-top: 9px;
}

.footer-nav-links li .footer-nav-link {
  color: #000;
}

.footer-right {
  width: 226px;
  padding-top: 3px;
}

.footer-social-links>li+li {
  margin-left: 7px;
}

.footer-social-links>li>.footer-social-link {
  width: 26px;
  height: 26px;
  background: #000;
  border-radius: 50%;
  line-height: 22px;
  text-align: center;
}

.copyright {
  margin-top: 8px;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 24px;
  font-weight: 300;
  color: #000;
  font-family: "Public Sans";
}

@media (min-width:767.98px) {
  .footer-social-links>li>.footer-social-link>img {
    width: auto !important;
  }
}

/* ================= Footer End ================= */

/* ================= Modal css Start ================= */
.modal-dialog {
  max-width: 748px;
}

.modal-content {
  min-height: 633px;
  border-radius: 0;
}

.modal-body {
  padding: 140px 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.modal .btn-close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 33px;
  right: 27px;
  padding: 0;
  background: none;
  box-shadow: none !important;
}

.modal .btn-close img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ================= Modal css Start ================= */
.student-plane {
  text-align: center;
  max-width: 460px;
  margin-top: -5px;
}

.student-plane h4 {
  font-size: 51px;
  line-height: 73px;
  margin-bottom: 10px;
  font-family: "EditorialNewItalic";
}

.student-plane h4+p {
  font-size: 26px;
  font-family: "PT Serif";
  line-height: 30px;
}

.modal-text-list {
  font-size: 26px;
  font-family: "PT Serif";
  line-height: 30px;
}

.modal-text-list li {
  position: relative;
  padding-left: 20px;
  text-align: left;
}

.modal-text-list li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: var(--black);
  left: 0;
  top: 15px;
}

.member-box {
  width: 364px;
  text-align: center;
}

.member-logo {
  width: 160px;
  margin: 0 auto;
  margin-bottom: 45px;
}

.member-box h3 {
  font-size: 51px;
  line-height: 48px;
  font-weight: 400;
  font-family: "PT Serif";
  margin-bottom: 29px;
  letter-spacing: -0.5px;
}

.member-box h5 {
  font-size: 36px;
  line-height: 44px;
  font-family: "EditorialNewItalic";
  margin-bottom: 36px;
}

.member-form .form-control {
  height: 46px;
  font-size: 24px;
  padding: 10px;
  font-weight: 300;
}

.member-form .form-group+.form-group {
  margin-top: 35px;
}

.member-form .form-group .btn {
  width: 100%;
  height: 46px;
  font-size: 24px;
  line-height: 24px;
}

#loginModal .modal-body {
  padding: 156px 0;
}

.login-form-modal {
  text-align: center;
  width: 364px;
}

.login-form-modal h4 {
  font-size: 51px;
  line-height: 73px;
  margin-bottom: 41px;
  font-family: "EditorialNewItalic";
}

.login-form-modal .form-group .login-google {
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  font-family: "Public Sans";
  padding: 12px 24px;
  border: 1px solid #040505;
  color: #4c5862;
  margin: 0 12px;
}

.login-form-modal .form-group .login-google span {
  width: 45px;
  display: block;
  margin-right: 20px;
}

.login-form-modal .form-group p {
  font-size: 24px;
  font-family: "Public Sans";
  line-height: 24px;
  text-transform: uppercase;
}

.login-form-modal .form-group .thank {
  margin-top: 24px;
  font-size: 18px;
  font-family: "Public Sans";
  line-height: 24px;
  display: inline-block;
  color: #040505;
}

.forgot-pass {
  position: absolute;
  bottom: 16px;
  font-size: 18px;
  color: #0064ad;
  font-weight: 400;
  font-family: "Public Sans";
}

.login-register {
  bottom: 57px;
  font-size: 18px;
  color: #0064ad;
  font-weight: 400;
  font-family: "Public Sans";
}

/* ================= Modal css End ================= */

/* ================= New Pages Start ================= */

.cc-content-box {
  border: 1px solid;
  padding: 40px;
}

.cc-content-box h4 {
  text-align: center;
  font-size: 51px;
  line-height: 73px;
  margin-bottom: 10px;
  font-family: "EditorialNewItalic";
}

.cc-content-box .modal-text-list li+li {
  margin-top: 15px;
}

.cc-content-box .login-form-modal h4 {
  margin-bottom: 41px;
}

.forgot-pass-outer {
  margin-top: 24px;
}

.cc-content-box a.forgot-pass {
  position: relative;
  bottom: 0;
}


/* ================= FOR SAFARI ONLY ================= */
_::-webkit-full-page-media,
.footer-nav-links li .footer-nav-link {
  white-space: nowrap;
}

_::-webkit-full-page-media,
.yourClass {}

@media (max-width:991.98px) {
  .cc-content-box h4 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media (max-width:767.98px) {
  .cc-content-box h4 {
    font-size: 36px;
  }

  .cc-content-box {
    padding: 25px;
  }

  .forgot-pass-outer {
    margin-top: 16px;
  }
}

/* ================= New Pages End ================= */
@media (max-width:1599.98px) {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    min-height: 500px;
  }

  .modal-body {
    padding: 100px 40px;
  }

  .student-plane h4 {
    font-size: 44px;
    line-height: 60px;
  }

  .modal-text-list {
    font-size: 22px;
  }

  .member-logo {
    width: 120px;
    margin-bottom: 36px;
  }

  .member-box h3 {
    font-size: 44px;
  }

  .member-box h5 {
    font-size: 30px;
    line-height: 36px;
  }

  .student-plane h4+p {
    font-size: 22px;
  }
}

@media (max-width:1499.98px) {
  .story-thumb-slider-content {
    width: 270px;
    padding: 20px;
  }

  .story-thumb-slider {
    width: 100%;
  }

  .story-img-slider-content {
    padding: 40px 0;
    width: calc(100% - 270px);
  }

  .story-img-slide-img {
    padding-bottom: 58%;
  }

  .story-img-slide-arrow {
    padding: 20px 0 0;
  }

  .hmb-text {
    font-size: 20px;
  }
}

@media (max-width:1399.98px) {
  .share-post-list.fixed {
    margin-right: 542px;
  }

  .news-post-content h1 {
    /*font-size: 90px;*/
    font-size: 111.36px;
    line-height: 90px;
  }
}

/* ========================================== 
   ! Large devices (desktops, less than 1200px)
   =========================================== */
@media (max-width:1199.98px) {
  .container-fluid {
    width: calc(100% - 12px);
  }

  .navbar-brand {
    width: 300px;
  }

  header .navbar {
    height: 140px;
    padding: 30px 0;
  }

  .post-meta {
    padding: 20px 0 0;
  }

  .page-link {
    font-size: 12px;
  }

  /********************************/
  .header-menu-body {
    padding: 40px 30px;
  }

  .header-menu-link {
    padding-left: 120px;
    min-height: 140px;
  }

  .header-menu-icon {
    width: 100px;
    height: 76px;
  }

  .hmb-icon {
    width: 140px;
  }

  .hmb-text {
    width: calc(100% - 140px);
    font-size: 18px;
    line-height: 28px;
    padding-left: 44px;
  }

  /**************  Home page responsive ******************/
  .news-post-content h1 {
    font-size: 88px;
    line-height: 94px;
  }

  .news-post-content h4 {
    font-size: 22px;
    line-height: 28px;
  }

  .news-post-meta {
    font-size: 22px;
    line-height: 27px;
  }

  .home-openion-content blockquote {
    font-size: 27px;
    line-height: 36px;
  }

  .hcu-logo {
    width: 100px;
    padding: 24px;
  }

  .hcu-head h3 {
    font-size: 40px;
    line-height: 44px;
  }

  .hcu-left h3 {
    font-size: 24px;
    line-height: 26px;
  }

  .hcu-left h5 {
    font-size: 14px;
  }

  .hculm-icon {
    width: 50px;
  }

  .hcu-right-upper {
    padding: 20px 30px;
  }

  .hcu-right-upper h2 {
    font-size: 64px;
    line-height: 70px;
  }

  .hcu-right-upper h2>span {
    font-size: 9px;
    line-height: 10px;
  }

  .hcu-right-upper h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .hcur-text h4 {
    font-size: 22px;
    line-height: 26px;
  }

  .hcur-text p {
    line-height: 20px;
  }

  .essay-series-post-content h4 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .eom-text h4 {
    font-size: 27px;
    line-height: 36px;
  }

  .eom-card-body blockquote {
    font-size: 20px;
    line-height: 30px;
  }

  .home-photo-story-img {
    margin-bottom: 24px;
  }

  .home-photo-story-content h4 {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 10px;
  }

  .post-by {
    font-size: 15px;
    line-height: 24px;
  }

  .grid-box h4 {
    font-size: 24px;
    line-height: 33px;
  }

  /* ============================== article single ================================== */
  .post-single-thumbnail {
    margin-bottom: 50px;
  }

  .block-text {
    font-size: 20px;
    line-height: 27px;
  }

  blockquote {
    font-size: 30px;
    line-height: 40px;
  }

  .post-single-img-50 {
    padding-left: 24px;
  }

  .block-text h2 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  /* ============================== subscription CSS ================================== */
  .subscription-list {
    width: calc(100% + 10px);
    margin-left: -5px;
  }

  .subscription-item {
    width: 50%;
    padding: 16px;
  }

  .subscription-box {
    padding: 40px;
  }

  .subscription-box h3 {
    font-size: 54px;
    line-height: 60px;
  }

  .subscription-box h4 {
    font-size: 33px;
    line-height: 36px;
  }

  .subscription-box h4 sub {
    font-size: 16px;
    line-height: 20px;
  }
}

/*==========================================
 ! Medium devices (tablets, less than 992px)
 ========================================== */
@media (max-width:991.98px) {

  /* .content-container.more-pad { padding: 60px 0; }*/
  header .navbar {
    height: 100px;
    padding: 24px 0;
  }

  .simple-heading {
    margin-bottom: 25px;
  }

  .simple-heading h4 {
    font-size: 16px;
  }

  .navbar-brand {
    width: 200px;
  }

  .header-right {
    margin-top: 0;
  }

  #header .header-right a {
    font-size: 12px;
    line-height: 17px;
  }

  .header-search-btn {
    width: 24px;
    padding: 3px 5px;
  }

  .post-meta {
    padding: 15px 0 40px;
  }

  .post-meta-content>h5 {
    font-size: 10px;
    padding: 0 20px;
  }

  .page-link {
    justify-content: center !important;
  }

  /****************************************/
  .navbar-menu-head {
    justify-content: start !important;
  }

  .navbar-menu-search-btn {
    width: 30px;
    margin-left: 10px;
  }

  .navbar-menu-search-btn img {
    width: 100%;
    object-fit: contain;
  }

  .header-menu-item {
    width: 50%;
  }

  .header-menu-item:nth-child(3n + 3)::after {
    opacity: 1;
  }

  .header-menu-item:nth-child(2n + 2)::after {
    opacity: 0;
  }

  .header-menu-item:nth-child(7) .header-menu-link::before,
  .header-menu-item:nth-child(8) .header-menu-link::before,
  .header-menu-item:nth-child(9) .header-menu-link::before {
    opacity: 1;
  }

  .header-menu-item:nth-child(9) .header-menu-link::before,
  .header-menu-item:nth-child(10) .header-menu-link::before {
    opacity: 0;
  }

  .header-menu-item:nth-child(8) .header-menu-link::before {
    opacity: 1 !important;
  }

  .header-menu-link.explore-journy-box {
    padding: 24px;
    justify-content: center;
  }

  .header-menu-link.explore-journy-box h5 {
    margin-bottom: 0;
    font-family: var(--font-en);
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1.1px;
  }

  .hmb-btn-group>a:first-child {
    font-size: 19px;
    text-transform: capitalize;
    line-height: 26px;
    text-decoration: underline;
  }

  /*************** home page css ********************/
  .most-interest-item {
    width: 50%;
  }

  .news-post-content h1 {
    font-size: 74px;
    line-height: 63px;
  }

  .news-post-content h4 {
    font-size: 15.45px;
    line-height: 18px;
  }

  .news-post-meta {
    font-size: 16px;
    line-height: 18px;
  }

  .hcu-head h3 {
    font-size: 34px;
    line-height: 41px;
    margin-top: 0;
  }

  .hcuh-right {
    font-size: 8px;
    line-height: 10px;
    width: 163px;
  }

  .hcu-head {
    min-height: 110px;
    align-items: center;
    display: flex;
  }

  .hculm-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  .hcu-logo {
    padding: 10px;
    width: 121px;
  }

  .hcu-logo img {
    width: 80px;
  }

  .hcu-left,
  .hcu-right {
    width: 100%;
  }

  .eom-card {
    max-width: 100%;
    margin: 40px auto 0;
  }

  .hms-right {
    width: 200px;
  }

  .hms-left {
    width: calc(100% - 200px);
    padding-right: 20px;
  }

  .hms-membership-logo {
    width: 200px;
  }

  .hms-membership {
    align-items: start;
  }

  .hms-membership-form-content {
    width: calc(100% - 200px);
  }

  .home-contributor-mid {
    width: 200px;
  }

  .home-contributor-mid h4 {
    font-size: 30px;
    line-height: 43px;
  }

  .home-contributor-mid h4>span {
    font-size: 20px;
    line-height: 20px;
  }

  .home-contributor-left {
    width: calc(100% - 440px);
  }

  .home-contributor-right {
    width: 240px;
  }

  .home-contributor-list {
    padding-top: 20px;
  }

  .cta-left {
    width: 220px;
  }

  .cta-content {
    justify-content: space-between;
    padding: 40px 30px;
    min-height: 200px;
  }

  .cta-left h3 {
    font-size: 27px;
    line-height: 32px;
  }

  .cta-right {
    width: 230px;
  }

  .crossword_slider .slick-arrow {
    height: 65%;
  }

  .video-block {
    margin-bottom: 50px;
  }

  /* =========================== about us css =============================== */
  .about-logo {
    height: auto;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 44px;
  }

  .other-author-content {
    padding-left: 30px;
  }

  .other-author-content h3 {
    font-size: 30px;
    line-height: 44px;
  }

  .other-author-content h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .other-author-content {
    font-size: 14px;
    line-height: 17px;
  }

  .other-author-img {
    height: 180px;
  }

  /* .about-logo img {
    width: 100%;
    height: auto;
  } */

  /* =========================== author css =============================== */
  .author-content {
    padding: 0 10px;
    align-items: start;
  }

  .content-container {
    padding: 47px 0;
  }

  .article-container {
    padding-top: 10px;
  }

  .cta-container {
    margin-bottom: 18px;
  }

  .most-interest-heading h3 {
    margin-top: 65px;
  }

  .author-single-container .post-auther-img {
    width: 280px;
  }

  .author-name {
    width: calc(100% - 280px);
  }

  .author-name h3 {
    font-size: 55px;
    line-height: 60px;
  }

  .article-item {
    padding: 30px 15px;
  }

  .article-text h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .article-text {
    padding-right: 30px;
  }

  .breadcrumb {
    margin-bottom: 10px;
  }

  .breadcrumb li {
    font-size: 10px;
    line-height: 12px;
  }

  .breadcrumb li+li::before {
    font-size: 12px;
  }

  /* =========================== profile css =============================== */
  .profile-img {
    width: 125px;
    height: 125px;
  }

  .profile-deatail {
    width: calc(100% - 275px);
    padding: 10px 0;
    padding-left: 24px;
  }

  .profile-deatail h6 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .profile-deatail h3 {
    font-size: 50px;
    line-height: 60px;
  }

  .profile-deatail p,
  .profile-deatail p a {
    font-size: 20px;
    line-height: 22px;
  }

  /* =========================== author-single-container css =============================== */
  .contact-list li {
    font-size: 18px;
    letter-spacing: 0.05px;
    line-height: 26px;
  }

  .contact-list li span,
  .contact-list li a {
    font-size: 24px;
    line-height: 30px;
    margin-top: 16px;
  }

  .contact-list li+li {
    margin-top: 30px;
  }

  /* =========================== crosswords css =============================== */
  .crossword-content {
    width: 70%;
    padding: 40px 40px 20px;
  }

  .crossword-thumbnail {
    width: 30%;
  }

  /* =========================== newsletter css =============================== */
  .newsletter-left {
    width: 24%;
  }

  .newsletter-mid {
    width: 300px;
  }

  .newsletter-title {
    min-height: auto;
  }

  .newsletter-title h2 {
    font-size: 100px;
  }

  .newsletter-right {
    width: calc(75% - 300px);
  }

  .newsletter-text {
    padding: 20px;
    min-height: auto;
  }

  .newsletter-cat {
    font-size: 16px;
  }

  .newsletter-weekend {
    font-size: 16px;
  }

  .newsletter-title h3 {
    font-size: 84px;
  }

  .newsletter-title h4 {
    font-size: 64px;
  }

  /* =========================== video 2 css =============================== */
  .fixed-block-text-content {
    font-size: 20px;
  }

  .fixed-block-text {
    line-height: 30px;
  }

  .fixed-block-text p {
    margin-bottom: 26px;
  }

  .podcast-plyr-item h3,
  .podcast-plyr-item h3 a {
    font-size: 28px;
    line-height: 33px;
  }

  .podcast-plyr-duration {
    font-size: 14px;
  }

  /* =========================== video css =============================== */
  .video-content {
    padding: 50px 30px 30px;
    font-size: 15px;
    line-height: 20px;
  }

  .video-content h3 {
    font-size: 25px;
    line-height: 31px;
    padding-left: 56px;
    margin-bottom: 6px;
  }

  .video-content h3 .video-play-btn {
    width: 44px;
    top: 0;
    left: 0;
  }

  .podcast-item {
    padding: 30px 15px;
  }

  .podcast-text h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .podcast-text {
    padding-right: 0;
    padding-left: 24px;
  }

  .podcast-text h3 {
    padding-right: 75px;
  }

  .podcast-duration {
    font-size: 14px;
  }

  .podcast-thumbnail {
    width: 220px;
  }

  .podcast-text {
    width: calc(100% - 220px);
  }

  /* =========================== category-heading css =============================== */
  .category-heading-box {
    padding-left: 100px;
    min-height: 90px;
  }

  .category-heading-box img {
    max-width: 76px;
  }

  .category-heading h3 {
    font-size: 40px;
  }

  .category-heading {
    margin-bottom: 50px;
  }

  /* =========================== subscription css =============================== */
  .tab-subscription-box {
    padding: 40px 30px;
  }

  .subscription-heading h3 {
    font-size: 33px;
    line-height: 44px;
  }

  .subscription-heading h3+p {
    font-size: 20px;
    line-height: 24px;
  }

  .ats-plane-box {
    margin-bottom: 20px;
  }

  .ats-plane h4 {
    font-size: 33px;
    line-height: 36px;
  }

  .ats-plane h4 span {
    margin-top: 4px;
  }

  .ats-plane h5 {
    font-size: 30px;
    line-height: 33px;
  }

  .its-plane h3 {
    font-size: 33px;
    line-height: 36px;
  }

  .its-plane h3 span {
    margin-top: 4px;
  }

  .its-plane h5 {
    font-size: 30px;
    line-height: 33px;
  }

  /* ============================== subscription CSS ================================== */
  .subscription-box {
    padding: 26px 35px 43px;
    font-size: 11px;
    line-height: 12px;
    outline-offset: -5px;
  }

  .subscription-box h3 {
    font-size: 46px;
    line-height: 51px;
  }

  .subscription-box h4 {
    font-size: 27px;
    line-height: 33px;
  }

  .subscription-box h4 sub {
    font-size: 9px;
    line-height: 11px;
  }

  /* =========================== footer css =============================== */
  .footer-left {
    width: 90px;
  }

  .footer-mid {
    width: calc(100% - 265px);
  }

  .footer-nav-links li {
    font-size: 12px;
    line-height: 12px;
  }

  .footer-right {
    width: 175px;
  }

  /* =========================== story-thumb-slider css =============================== */
  .story-thumb-slider-content {
    width: 100%;
  }

  .story-slide-container {
    flex-flow: column-reverse;
  }

  .story-img-slider-content {
    width: 100%;
  }

  .story-thumb-slider {
    max-width: 100%;
  }

  .story-img-slide-text {
    font-size: 18px;
    line-height: 27px;
    padding-top: 20px;
  }

  /* ============================== Modal CSS ================================== */
  .modal-body {
    padding: 120px 40px;
  }

  .modal-content {
    min-height: auto;
  }

  .student-plane h4,
  .login-form-modal h4 {
    font-size: 44px;
    line-height: 50px;
  }

  .student-plane {
    max-width: 500px;
  }

  .member-logo {
    width: 140px;
    margin-bottom: 34px;
  }

  .member-box h3 {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .member-box h5 {
    font-size: 30px;
    line-height: 33px;
  }
}

/* =================================================  
  ! Small devices (landscape phones, less than 768px)
  ================================================= */
@media (max-width:767.98px) {
  #main {
    overflow: hidden;
  }

  .main_post_container {
    padding: 36px 0;
  }

  .page-heading h1 {
    font-size: 26px;
    line-height: 31px;
  }

  .page-heading h3 {
    font-size: 15px;
    line-height: 20px;
    margin-top: 16px;
  }

  .btn {
    min-width: 106px;
    font-size: 14px !important;
    line-height: 14px !important;
    padding: 7px 10px;
  }

  .btn.btn-lg {
    height: 27px;
    font-size: 11px !important;
    line-height: 11px !important;
    padding: 8px 10px;
  }

  .section-heading h5 {
    font-size: 12px;
    letter-spacing: 3.86px;
    padding: 0 36px;
  }

  .simple-heading {
    margin-bottom: 25px;
  }

  .simple-heading h4 {
    font-size: 12px;
    letter-spacing: 3.5px;
    line-height: 14px;
  }

  .heading {
    margin-bottom: 30px;
  }

  .heading h4 {
    font-size: 38px;
    line-height: 46px;
    text-transform: capitalize;
    font-family: "EditorialNewRegular400";
  }

  .form-control {
    height: 40px;
    padding: 10px 14px;
  }

  .navbar-brand {
    width: 144px;
    bottom: 10px;
  }

  header .navbar {
    height: 70px;
    padding: 20px 0 15px;
    align-items: end;
  }

  header .navbar .nav-inside {
    align-items: end;
  }

  .navbar-toggler {
    margin-left: -10px;
    margin-bottom: -4px;
  }

  .navbar-toggler .navbar-toggler-icon {
    width: 26px;
  }

  .navbar-toggler .navbar-toggler-icon:before,
  .navbar-toggler .navbar-toggler-icon:after {
    width: 26px;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon:after {
    bottom: -9px;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon:before {
    top: -9px;
  }

  .header-search-btn {
    width: 25px;
    padding: 2px;
    margin-right: -5px;
  }

  .post-meta {
    padding: 10px 0 0;
  }

  .post-meta-content>h5 {
    font-size: 7px;
    letter-spacing: 2.24px;
    padding: 0 12px;
  }

  /****************=== header2 css ===*******************/
  #header1 .navbar {
    height: 60px;
  }

  #header1 .header-logo {
    width: 40px;
  }

  #header1 .navbar-toggler {
    margin-left: -10px;
  }

  #header1 .navbar-toggler .navbar-toggler-icon {
    width: 24px;
  }

  #header1 .navbar-toggler .navbar-toggler-icon:before,
  #header1 .navbar-toggler .navbar-toggler-icon:after {
    width: 24px;
  }

  #header1 .navbar-toggler.collapsed .navbar-toggler-icon:before {
    top: -8px;
  }

  #header1 .navbar-toggler.collapsed .navbar-toggler-icon:after {
    bottom: -8px;
  }

  /***********************************/
  .header-menu-body {
    padding: 30px 14px;
  }

  .navbar-menu-head {
    height: 60px;
  }

  .navbar-menu-head .navbar-toggler {
    width: 44px;
    height: 44px;
    margin-left: -12px;
    margin-top: -9px;
  }

  .navbar-menu-head .navbar-toggler .navbar-toggler-icon {
    width: 24px;
  }

  .navbar-menu-head .navbar-toggler .navbar-toggler-icon:before,
  .navbar-menu-head .navbar-toggler .navbar-toggler-icon:after {
    width: 24px;
  }

  .navbar-menu-search-btn {
    width: 22px;
    margin-left: 2px;
    line-height: 22px;
    margin-top: 4px;
  }

  .navbar-menu-logo {
    transform: translate(-50%, 0);
    bottom: 4px;
    display: block;
    top: auto;
  }

  .header-menu-list::before {
    display: none;
  }

  .navbar-menu-logo {
    transform: translate(-50%, 0);
    bottom: 9px;
    display: block;
    top: auto;
    width: 40px;
  }

  .header-menu-link {
    padding: 10px;
    min-height: 108px;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .header-menu-link::before {
    width: 100%;
  }

  .header-menu-link::after {
    height: 100%;
  }

  .header-menu-icon {
    position: relative;
    transform: none;
    height: 47px;
    margin-bottom: 14px;
    top: auto;
    left: auto;
    max-width: 80px;
  }

  .header-menu-link span {
    font-size: 7px;
    line-height: 10px;
    letter-spacing: 2.4px;
  }

  .header-menu-link.explore-journy-box h5 {
    font-size: 15px;
    line-height: 18px;
  }

  .header-menu-bottom {
    padding: 16px 0;
  }

  .hmb-btn-group>a:first-child {
    min-width: 70px;
  }

  .header-menu-item::after {
    background-color: #bcbdbf;
  }

  .header-menu-link::before {
    background-color: #bcbdbf;
  }

  /*************home popular_slider **********************/
  .btn.btn-sm {
    height: 34px;
  }

  .home_popular_slider .grid-img::before {
    display: none;
  }

  .grid-list .slick-list {
    padding: 0 36px;
  }

  .grid-list.space-more .slick-list {
    padding: 0 40px;
  }

  .grid-list .slick-list {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .essay-series-post-content p {
    display: block;
    font-size: 18px;
    line-height: 25px;
    padding: 0 25px;
  }

  .essay-series-post-content h4 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 23px;
  }

  .essay-series-post-list {
    margin-top: -8px;
    width: calc(100% + 32px);
    margin-left: -16px;
    display: block;
  }

  .essay-series-post-list .slick-list {
    overflow: visible;
    padding-right: 40px;
  }

  .essay-series-post-item {
    padding: 0 10px;
  }

  .essay-series-post-img {
    padding-bottom: 70%;
    display: block;
  }

  .essay-series-post-content {
    padding: 21px 0;
  }

  .post-by {
    font-size: 19px;
    line-height: 20px;
  }

  .post-date {
    font-size: 11px;
    line-height: 12px;
  }

  .post-date-1 {
    justify-content: center;
  }

  .eom-content {
    flex-flow: column;
    padding-right: 0;
  }

  .eom-img {
    width: calc(100% + 14px);
    margin-left: -7px;
    height: 232px;
  }

  .eom-text {
    width: 100%;
    padding: 14px 30px;
    padding-bottom: 0;
    text-align: center;
    font-size: 19px;
    line-height: 24px;
  }

  .eom-text h4 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 21px;
  }

  .eom-text p {
    margin-bottom: 20px;
  }

  .eom-card {
    width: calc(100% + 40px);
    margin-left: -20px;
    max-width: max-content;
  }

  .eom-card-body {
    padding: 30px 29px 20px;
  }

  .eom-card-body blockquote {
    font-size: 26px;
    line-height: 43px;
    margin-bottom: 30px;
  }

  .eom-card-bottom a {
    padding: 10px 20px;
  }

  .home-photo-story-img {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: 18px;
  }

  .home-photo-story-content h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .home-photo-story-text p {
    padding: 0 20px;
    margin-bottom: 12px;
  }

  .home-photo-story-content {
    font-size: 12px;
    line-height: 15px;
  }

  .home-photo-story-content .post-by {
    font-size: 11px;
    line-height: 13px;
  }

  .home-photo-story-content .post-date {
    font-size: 5px;
    line-height: 7px;
  }

  .hms-left {
    width: 100%;
    padding: 0;
  }

  .hms-right {
    width: 100%;
    margin-top: 100px;
  }

  .hms-membership {
    flex-flow: column;
  }

  .hms-membership-logo {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 376px;
    text-align: center;
  }

  .hms-membership-logo img {
    width: auto;
    height: 100%;
  }

  .hms-membership-form-content {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 27px;
    padding: 0 30px;
    padding-top: 30px;
  }

  .crosword-contetnt h5 {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 12px;
  }

  .hms-membership-form-content p {
    margin-bottom: 0;
  }

  .hms-membership-form {
    padding-top: 64px;
  }

  .home-contributor-list {
    padding-bottom: 34px;
  }

  .home-contributor-box {
    flex-flow: column;
  }

  .home-contributor-left {
    width: 100%;
  }

  .home-contributor-img {
    height: 121px;
  }

  .home-contributor-mid {
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: column-reverse;
  }

  .home-contributor-right {
    width: 100%;
    text-align: center;
    padding-bottom: 0;
    display: none;
  }

  .home-contributor-mid h4>span {
    font-size: 18px;
    line-height: 18px;
    display: inline-block;
    padding-left: 5px;
  }

  .home-contributor-mid h6 {
    font-size: 30px;
    line-height: 43px;
    font-family: "EditorialNewItalic";
    text-transform: capitalize;
  }

  .home-contributor-mid h4 {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 18px;
    font-family: "PT Serif";
  }

  .home-podcast-item {
    width: 100%;
  }

  .home-podcast-list {
    padding-bottom: 10px;
    width: calc(100% + 26px);
    margin-left: -13px;
  }

  .cta-normal-content p a {
    display: block;
    margin-top: 16px;
  }

  .cta-content {
    flex-flow: column;
    width: calc(100% + 44px);
    margin-left: -22px;
    padding: 30px 15px;
  }

  .cta-left {
    margin-bottom: 14px;
  }

  .cta-mid {
    display: flex;
    width: 100%;
    padding: 0;
    justify-content: center;
  }

  .cta-mid .form-group+.form-group {
    margin-top: 0;
    margin-left: 54px;
  }

  .cta-right {
    width: 100%;
    margin-top: 20px;
  }

  .most-interest-container {
    position: relative;
  }

  .most-interest-container {
    padding-bottom: 0 !important;
  }

  .most-interest-container::before {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    height: 0.1px;
    background: var(--black);
    top: 0;
  }

  .most-interest-item {
    width: 100%;
  }

  .most-interest-link {
    padding: 0;
    flex-flow: column;
  }

  .most-interest-heading h3 {
    font-size: 31px;
  }

  .most-interest-link::before {
    display: none;
  }

  .most-interest-icon {
    position: relative;
    left: auto;
    transform: none;
    top: auto;
    width: 100%;
    text-align: center;
    padding: 25px;
    height: 244px;
    margin-bottom: 30px;
  }

  .most-interest-link span {
    display: block;
    width: 100%;
    padding: 28px 0;
    text-align: center;
    border: 1px solid #040505;
    border-left: 0;
    border-right: 0;
  }

  .most-interest-icon img {
    width: 200px;
    margin: 0 auto;
    height: 100%;
  }

  .hcu-content {
    width: calc(100% + 44px);
    margin-left: -22px;
  }

  .hcu-head {
    padding-right: 30px;
    padding-left: 126px;
  }

  .hcuh-right {
    display: none;
  }

  .hcu-head h3 {
    line-height: 41px;
    margin-top: 3px;
  }

  .hcu-left-upper {
    padding: 15px 24px 22px;
  }

  .hcu-left h6 {
    margin-bottom: 7px;
    letter-spacing: 0.4px;
  }

  .hcu-left h3 {
    margin-bottom: 0;
  }

  .hcu-left h5 {
    font-size: 11px;
  }

  .hculm-left {
    padding: 10px 23px 5px;
  }

  .hculm-right {
    padding: 15px 21px 17px;
  }

  .hculm-icon {
    right: 12px;
  }

  .hcu-left-lower {
    padding: 19px 24px 17px;
  }

  .hcu-right-upper {
    padding: 27px 20px 14px;
  }

  .hcu-right-upper h2 {
    letter-spacing: -3px;
  }

  .hcu-right-upper h2>span {
    letter-spacing: normal;
    line-height: 9px;
    font-size: 8px;
  }

  .hcu-right-upper h4 {
    margin-top: 3px;
    line-height: 18px;
  }

  .hcu-right-lower {
    padding: 20px 18px 20px 24px;
  }

  .hcur-text h4 {
    font-size: 18px;
    line-height: 21px;
  }

  .hcur-text {
    padding-right: 6px;
  }

  .hcur-text p {
    font-size: 11px;
    line-height: 17px;
  }

  .news-post-img {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 208px;
    margin-bottom: 10px;
  }

  .news-post-img img {
    height: 100%;
  }

  .news-post-content h1 {
    margin-bottom: 12px;
  }

  .news-post-meta {
    margin-bottom: 20px;
  }

  .news-post-meta span {
    min-width: auto;
  }

  .article-single-container .news-post-meta {
    margin-bottom: 18px;
    /*flex-flow: column;*/
    align-items: center;
  }

  .article-single-container .news-post-meta span {
    min-width: auto;
    /*margin-top: 15px;*/
  }

  .grid-item {
    width: 100%;
  }

  .grid-img {
    margin-bottom: 10px;
    height: 224px;
  }

  .grid-box h4 {
    font-size: 31px;
    line-height: 37px;
    min-height: unset;
  }

  .grid-box .post-by {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .home-openion-content blockquote {
    font-size: 23px;
    line-height: 34px;
  }

  .home-openion-content {
    margin: -3px;
    padding: 0 25px;
  }

  .home-openion-content .post-by {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .hms-membership-form .form-group .form-control {
    height: 35px;
    padding: 6px 14px;
    font-size: 17px;
  }

  .hms-membership-form .form-group+.form-group {
    margin-top: 16px;
  }

  .crosword-contetnt {
    margin-bottom: 18px;
  }

  .home-contributor-item+.home-contributor-item {
    margin-top: 38px;
  }

  .home-newsletter-form-content h5 {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 19px;
  }

  .home-newsletter-form .form-group .input {
    height: 30px;
  }

  .custom-arrow {
    padding: 24px 7px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 18px;
    font-family: "FoundryGridnikW03Bold";
    letter-spacing: 3px;
    position: relative;
    /*border-bottom: 1px solid #040505;*/
  }

  .custom-arrow-btn {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .video-block {
    margin-bottom: 40px;
  }

  /* =========================== single article css =============================== */
  .post-single-thumbnail {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: 11px;
  }

  .article-single-container .news-post-content h1 {
    margin-bottom: 22px;
  }

  .article-single-container .news-post-content h4 {
    margin-bottom: 18px;
  }

  .post-category-heading {
    margin-top: 29px;
    font-size: 8px;
    letter-spacing: 2.1px;
    margin-bottom: 30px;
  }

  .pch-img {
    height: 68px;
    margin-bottom: 10px;
  }

  .block-text {
    font-size: 17px;
    line-height: 23.5px;
  }

  .block-text>p:first-child>span,
  .block-text span.drop-cap {
    font-size: 53px;
    margin-left: -2px;
    line-height: 50px;
    margin-bottom: -4px;
  }

  .block-text p,
  .block-text p:not(:last-child) {
    margin-bottom: 24px !important;
  }

  .block-text p:last-child {
    margin-bottom: 0 !important;
  }

  .block-text h4 {
    font-size: 22px;
    line-height: 27px;
  }

  blockquote {
    font-size: 25px;
    line-height: 34px;
  }

  .post-single-img-50 {
    float: left;
    padding-left: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: 36px;
  }

  .post-single figcaption {
    font-size: 7px;
    line-height: 12px;
    padding-left: 23px;
    text-align: left;
    letter-spacing: 2.5px;
    margin-top: 17px;
  }

  .cta-blog {
    margin: 40px 0;
    padding: 18px 0;
    font-size: 14px;
    line-height: 23px;
  }

  .post-single-img-100 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: 74px;
  }

  .post-single-img-100 figcaption+figcaption {
    padding-right: 20px;
  }

  .post-single-img-100 figcaption {
    font-size: 8px;
    padding-left: 19px;
    line-height: 10px;
  }

  .post-author-content {
    width: 100%;
  }

  .post-author-container h5 {
    margin-bottom: 15px;
  }

  .post-auther-img {
    width: 144px;
  }

  .post-author-name {
    width: calc(100% - 144px);
    padding-left: 26px;
    font-size: 11px;
    line-height: 14px;
    padding-right: 0;
  }

  .post-auther {
    padding: 0;
  }

  .post-author-name h3 {
    font-size: 21.4px;
    line-height: 20px;
  }

  .post-author-name h6 {
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.35px;
    margin-bottom: 6px;
  }

  .share-post {
    margin-top: 25px;
    width: 100%;
    text-align: left !important;
  }

  .share-post .share-post-list {
    justify-content: start;
  }

  .share-post-list li a {
    width: 36px;
    height: 36px;
    line-height: 30px;
    padding: 3px;
  }

  /* =========================== cross-theme css =============================== */
  .content-container.crossword-theme-container {
    padding-top: 24px;
  }

  .crossword-slide-item {
    padding: 0 15px;
  }

  .crossword-slide-list .slick-list {
    padding: 0 50px;
  }

  .crossword-slide-list .slick-list {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .crossword-slide-box h4 {
    font-size: 31px;
    line-height: 37px;
  }

  .cross-theme-content {
    margin-top: 30px;
    height: auto;
    overflow: visible;
    position: relative;
  }

  .cross-theme-content .cross-theme-box {
    width: 50%;
    position: relative;
    padding: 0;
  }

  .cross-theme-content .cross-theme-box h4 {
    font-size: 27px;
    line-height: 30px;
    margin-bottom: 8px;
    position: relative;
    padding-bottom: 4px;
  }

  .cross-theme-text {
    font-size: 12px;
    line-height: 16px;
    padding-right: 15px;
    overflow-y: auto;
    height: 400px;
  }

  .cross-theme-content .cross-theme-box h4::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--black);
    left: 0px;
    bottom: 0;
  }

  .cross-theme-content .cross-theme-box::before {}

  .cross-theme-content::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: var(--black);
    bottom: 0;
    left: calc(50% + 6px);
  }

  .cross-theme-content .cross-theme-box:last-child {
    padding-left: 20px;
  }

  .cross-theme-content .cross-theme-box:last-child h4::before {
    width: calc(100% + 20px);
    margin-left: -20px;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #040505;
  }

  .home .content-container.cta-container {
    padding-bottom: 0;
  }

  .home .footer-content {
    border: none;
  }

  /* =========================== about us css =============================== */
  .page-links {
    width: calc(100% + 20px);
    margin-left: -10px;
    justify-content: center !important;
  }

  .page-link {
    font-size: 8px;
    padding-top: 0;
    line-height: 11px;
    letter-spacing: 2.8px;
  }

  .page-links li {
    padding: 4px 11px;
  }

  .content-container.about-container {
    padding-top: 20px;
  }

  .about-logo {
    padding: 0 5%;
  }

  .other-author-box {
    flex-flow: column;
  }

  .other-author-img {
    width: 100%;
    height: auto;
  }

  .other-author-content {
    width: 100%;
    padding: 15px;
    text-align: center;
  }

  .other-author-content p,
  .other-author-content .read-more-btn {
    display: none;
  }

  .other-author-content h3 {
    line-height: 35px;
  }

  .other-author-content h5 {
    margin-bottom: 0;
  }

  .other-author-item+.other-author-item {
    margin-top: 24px;
  }

  /* =========================== author-single-container css =============================== */
  .author-single-container .post-auther-img {
    width: 142px;
  }

  .author-name {
    width: calc(100% - 142px);
    padding: 0 0 0 25px;
  }

  .author-name h3 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .author-name h6 {
    font-size: 8px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .author-name blockquote {
    font-size: 12px;
    padding-top: 0;
    line-height: 14px;
    margin-bottom: 9px;
  }

  .share-box h5 {
    font-size: 7px;
    line-height: 12px;
    margin-bottom: 6px;
  }

  .share-box .share-post-list li a {
    width: 24px;
    height: 24px;
    padding: 3px;
    font-size: 12px;
    line-height: 19px;
  }

  .share-box .share-post-list li+li {
    margin-left: 5px;
  }

  .filter-content {
    padding-bottom: 4px;
  }

  .filter-content h5,
  .filter-box,
  .filter-box>select {
    font-size: 9px;
    line-height: 14px;
  }

  .filter-box>select {
    background-size: 6px;
    padding-right: 11px;
  }

  .article-box {
    flex-flow: column-reverse;
  }

  .article-thumbnail {
    padding: 0;
    width: 100%;
  }

  .article-text {
    width: 100%;
    padding: 12px 0;
  }

  .breadcrumb {
    margin-bottom: 6px;
  }

  .article-item {
    padding: 0;
    padding-bottom: 20px;
  }

  .article-text p {
    margin-bottom: 19px;
  }

  .post-date {
    margin-top: 11px;
  }

  .article-list {
    padding: 22px 0;
  }

  .article-item+.article-item {
    padding: 0;
    padding-top: 30px;
  }

  /* =========================== author-single-container css =============================== */
  .content-container.contact-container {
    padding-top: 0;
  }

  .contact-list li {
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 16px;
  }

  .contact-list li span,
  .contact-list li a {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
  }

  .contact-list li+li {
    margin-top: 25px;
  }

  /* =========================== crossword-single-container css =============================== */
  .crossword-list .crossword-item {
    margin-top: 0;
  }

  .crossword-content {
    width: 45%;
    padding: 14px 16px;
  }

  .crossword-content .crossword-cat {
    position: relative;
    font-size: 8px;
    letter-spacing: 2.6px;
    line-height: 12px;
    top: auto;
    left: auto;
    margin-bottom: 6px;
    display: block;
  }

  .crossword-content h3 {
    font-size: 22px;
    line-height: 26px;
  }

  .crossword-content p {
    display: none;
  }

  .crossword-thumbnail {
    width: 55%;
    padding: 16px;
  }

  .crossword-content .post-by {
    font-size: 11px;
    line-height: 20px;
  }

  /* =========================== newsletter css =============================== */
  .newsletter-left {
    width: 100%;
  }

  .newsletter-mid {
    width: 100%;
    border: none;
  }

  .newsletter-right {
    width: 100%;
    border: none;
  }

  .newsletter-icon {
    padding: 24px;
  }

  .newsletter-cat {
    min-height: 60px;
    border-bottom: 1px solid var(--black);
  }

  .newsletter-weekend {
    min-height: 60px;
    border-bottom: 1px solid var(--black);
  }

  .newsletter-link {
    padding: 16px;
    min-height: 60px;
  }

  .newsletter-item+.newsletter-item {
    margin-top: 40px;
  }

  .newsletter-form .btn {
    min-width: 84px;
    font-size: 14px !important;
  }

  /* =========================== photo-story-full css =============================== */
  .photo-story-full-list {
    margin-top: 33px;
  }

  .photo-story-full-item {
    margin-top: 33px;
    font-size: 17px;
    line-height: 24px;
  }

  .photo-story-full-img {
    margin-bottom: 33px;
  }

  /* =========================== search-bar css =============================== */
  .search-bar .form-control {
    height: 30px;
    font-size: 10px;
    padding: 6px 10px;
  }

  .search-bar .search-bar-btn {
    width: 30px;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* =========================== podcast-2 css =============================== */
  .podcast-plyr-item {
    padding: 12px 0 14px;
  }

  .podcast-plyr-player {
    margin-bottom: 22px;
  }

  .podcast-plyr-item h3,
  .podcast-plyr-item h3 a {
    font-size: 25px;
    line-height: 31px;
  }

  .podcast-plyr-item .breadcrumb {
    margin-bottom: 8px;
  }

  .podcast-plyr-item h3,
  .podcast-plyr-item h3 a {
    padding-right: 66px;
  }

  .podcast-plyr-duration {
    font-size: 11px;
    line-height: 22px;
    top: 14px;
  }

  .fixed-block-text-content {
    padding: 12px 10px;
    font-size: 15px;
    line-height: 21px;
  }

  .fixed-block-text .simple-heading {
    margin-bottom: 14px;
  }

  .fixed-block-text {
    line-height: 21px;
    padding-right: 10px;
  }

  .fixed-block-text-content {}

  .fixed-block-text p {
    margin-bottom: 14px;
  }

  .fixed-block-text p:last-child {
    margin-bottom: 0;
  }

  .fixed-block-text {
    height: 328px;
  }

  /* =========================== video-content css =============================== */
  .video-list {
    padding-top: 22px;
  }

  .video-list {
    padding-top: 22px;
  }

  .video-item {
    margin-top: 0;
  }

  .video-item+.video-item {
    margin-top: 34px;
  }

  .video-box {
    flex-flow: column-reverse;
  }

  .video-content {
    width: 100%;
    padding: 13px 20px 23px;
  }

  .video-thumbnail {
    width: 100%;
    height: 160px;
  }

  .video-content h3 {
    margin-bottom: 3px;
  }

  .video-content p {
    margin-bottom: 5px;
  }

  .video-content .video-cat {
    font-size: 9px;
    line-height: 22px;
    position: relative;
    margin-bottom: 0px;
    display: block;
    left: auto;
    top: auto;
  }

  /* =========================== podcast css =============================== */
  .podcast-box {
    flex-flow: row-reverse;
  }

  .podcast-thumbnail {
    width: 106px;
    padding: 0;
  }

  .podcast-thumbnail .podcast-ply-btn {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .podcast-text {
    width: calc(100% - 106px);
    padding: 0;
    padding-right: 16px;
  }

  .podcast-item {
    padding: 0;
  }

  .podcast-list {
    padding-top: 26px;
    margin-bottom: 36px;
  }

  .podcast-item+.podcast-item {
    padding-top: 32px;
    margin-top: 34px;
  }

  .podcast-text .breadcrumb li {
    font-size: 9px;
    line-height: 11px;
  }

  .podcast-text h3 {
    font-size: 22px;
    line-height: 27px;
    padding-right: 0;
  }

  .podcast-text h3 br {
    display: none;
  }

  .podcast-text p {
    margin-bottom: 7px;
  }

  .podcast-text .post-date {
    margin-top: 7px;
  }

  .podcast-duration {
    color: #fff;
    font-size: 11px;
    right: 12px;
    bottom: 4px;
    top: auto;
  }

  /* =========================== profile css =============================== */
  .profile-content {
    padding: 0 20px;
  }

  .profile-img {
    width: 78px;
    height: 78px;
  }

  .profile-deatail {
    width: calc(100% - 78px);
    padding: 6px 0;
    padding-left: 18px;
  }

  .profile-deatail h6 {
    font-size: 7px;
    line-height: 9px;
    margin-bottom: 2px;
    letter-spacing: 2.2px;
  }

  .profile-deatail h3 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 2px;
  }

  .profile-deatail p,
  .profile-deatail p a {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.5px;
  }

  /* =========================== subscription css =============================== */
  .subscription-tabs-content {
    padding-top: 10px;
    border: none;
    padding-bottom: 44px;
  }

  .active-tab-subscription .btn {
    margin-bottom: 10px;
  }

  .active-tab-subscription p {
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.1px;
  }

  .subscription-tabs-link {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 2px;
  }

  .subscription-tabs {
    width: calc(100% + 20px);
    margin-left: -10px;
    justify-content: space-evenly !important;
  }

  .tab-subscription-box {
    padding: 16px 20px 20px;
  }

  .active-tab-subscription {
    padding: 0;
  }

  .ats-plane-box {
    display: flex;
    margin-bottom: 10px;
  }

  .subscription-heading {
    margin-bottom: 16px;
  }

  .ats-plane-box .subscription-heading {
    margin-bottom: 0;
    width: calc(100% - 96px);
    padding-right: 27px;
  }

  .ats-plane {
    padding: 0;
    width: 96px;
    padding-left: 22px;
    flex-flow: column;
    text-align: right;
    justify-content: start;
  }

  .subscription-heading h3 {
    font-size: 20px;
    line-height: 25px;
  }

  .subscription-heading h3+p {
    font-size: 10px;
    line-height: 10px;
    margin-top: 6px;
  }

  .ats-plane h4 {
    margin: 0;
    font-size: 19px;
    line-height: 21px;
    margin-top: 2px;
  }

  .ats-plane h4 span {
    font-size: 8px;
    line-height: 8px;
    margin-top: 2px;
    display: block;
    text-align: right;
    letter-spacing: 0px;
  }

  .ats-plane h5 {
    font-size: 14px;
    line-height: 16px;
    margin-top: 22px;
    margin-right: 3px;
  }

  .ats-plane h5 span {
    font-size: 7px;
    line-height: 9px;
    margin-bottom: 0;
  }

  .ats-icon {
    width: 17px;
    height: 17px;
    top: 4px;
  }

  .ats-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: inherit;
  }

  .its-plane {
    padding: 10px 20px 6px;
  }

  .its-plane h3 {
    font-size: 15px;
    line-height: 15px;
  }

  .its-plane h3 span {
    font-size: 8px;
    line-height: 8px;
    margin-top: 0;
  }

  .its-plane h5 {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0;
  }

  .its-plane h5 span {
    font-size: 7px;
    line-height: 7px;
    margin-bottom: 2px;
    letter-spacing: 0;
  }

  .its-recommemd {
    width: 100%;
    height: auto;
  }

  .its-recommemd span {
    transform: none;
    top: 0;
    left: 0;
    font-size: 5px;
    line-height: 8px;
    text-align: left;
    padding: 0 19px;
  }

  /* =========================== page-link css =============================== */
  .category-tab-list {
    width: 100%;
    margin-top: -20px;
    margin-left: 0px;
    gap: 5px;
    /*justify-content: start !important;*/
  }

  .article-thumbnail img,
  .article-thumbnail a img {
    width: 100%;
  }

  .category-tab-link {
    /*font-size: 11px;*/
    font-size: 7px;
    line-height: 13px;
  }

  .category-tab-list li {
    padding: 0 12px 0 0;
    width: 50%;
  }

  .category-heading-box {
    padding-left: 0px;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .category-heading-box img {
    position: relative;
    height: 105px;
    margin-bottom: 20px;

  }

  .category-heading h3 {
    font-size: 27px;
    line-height: 33px;
  }

  .category-heading {
    margin-bottom: 40px;
  }

  /* ============================== subscription CSS ================================== */
  .subscription-list {
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  .subscription-item {
    width: 100%;
  }

  .subscription-box .subscription-i {
    width: 20px;
    top: 10px;
    right: 10px;
    height: 20px;
  }

  .subscription-box .recommend {
    width: 130px;
    height: 130px;
  }

  .subscription-box .recommend span {
    padding: 2px;
    font-size: 6px;
    text-align: center;
  }

  .subscription-intro h4 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 12px;
  }

  .subscription-intro p {
    margin-bottom: 24px;
  }

  /* =========================== Footer css =============================== */
  .footer-content {
    flex-flow: column;
    padding-top: 40px;
  }

  .footer-left {
    width: 72px;
    margin-bottom: 44px;
  }

  .footer-mid {
    width: 100%;
    flex-flow: column;
    text-align: center;
  }

  .footer-nav-links li {
    font-size: 14px;
    line-height: 16px;
  }

  .footer-nav-links {
    padding: 0;
  }

  .footer-nav-links li+li {
    margin-top: 27px;
  }

  .footer-nav-links+.footer-nav-links {
    margin-top: 28px;
  }

  .footer-right {
    width: 100%;
    text-align: center;
    margin-top: 29px;
  }

  .footer-social-links {
    justify-content: center;
  }

  .footer-social-links>li>.footer-social-link {
    width: 37px;
    height: 37px;
    line-height: 32px;
  }

  .footer-social-links>li+li {
    margin-left: 38px;
  }

  .copyright {
    font-size: 8px;
    margin-top: 29px;
  }

  /* =============== Modal css ================== */
  .modal-body,
  #loginModal .modal-body {
    padding: 80px 24px 40px;
  }

  #loginModal .modal-body {
    padding: 80px 24px 80px;
  }

  .modal .btn-close {
    width: 20px;
    height: 20px;
    top: 20px;
    right: 23px;
  }

  .student-plane h4 {
    font-size: 36px;
  }

  .login-form-modal h4 {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .login-form-modal .form-group .login-google {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    padding: 10px 20px;
  }

  .login-form-modal .form-group .login-google span {
    width: 36px;
  }

  .login-form-modal .form-group p {
    font-size: 16px;
    line-height: 16px;
  }

  .login-form-modal .form-group .thank {
    font-size: 16px;
    line-height: 16px;
    margin-top: 16px;
  }

  .forgot-pass {
    bottom: 20px;
    font-size: 16px;
    line-height: 16px;
  }

  .login-register {
    bottom: 10px;
    font-size: 16px;
    line-height: 16px;
  }

  .modal-text-list,
  .student-plane h4+p {
    font-size: 20px;
    line-height: 27px;
  }

  .modal-text-list li::before {
    width: 8px;
    height: 2px;
    top: 15px;
  }

  .member-box {
    max-width: 100%;
    width: 100%;
  }

  .login-form-modal {
    max-width: 100%;
    width: 100%;
  }

  .member-logo {
    width: 100px;
    margin-bottom: 30px;
  }

  .member-box h3 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .member-box h5 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .member-form .form-group+.form-group {
    margin-top: 24px;
  }

  .member-form .form-control {
    height: 36px;
    font-size: 16px;
    padding: 6px 10px;
  }

  .member-form .form-group .btn {
    height: 36px;
  }

  a.email-mag {
    word-break: break-all;
  }

  /* =============== story-img-slider css ================== */
  .story-img-slider-content {
    padding-bottom: 20px;
  }

  .story-thumb-slider .slick-list {
    overflow: visible;
    padding: 0 !important;
  }

  .story-thumb-slider .slick-prev {
    left: -10px;
  }

  .story-thumb-slider .slick-next {
    right: -10px;
  }

  .story-img-slide-text {
    font-size: 16px;
    line-height: 24px;
  }
}

/* ======================================================
  ! Extra small devices (portrait phones, less than 576px)
  ====================================================== */
@media (max-width:374.98px) {
  .hmb-btn-group .btn {
    min-width: 90px;
    font-size: 12px !important;
  }

  .news-post-content h1 {
    font-size: 54px;
    line-height: 52px;
  }

  .most-interest-icon {
    height: 200px;
  }

  .most-interest-icon img {
    max-width: 150px;
  }

  .grid-box h4 {
    font-size: 27px;
    line-height: 35px;
  }

  .author-content {
    padding: 0;
  }

  .author-single-container .post-auther-img {
    width: 124px;
  }

  .author-name {
    width: calc(100% - 124px);
    padding-left: 16px;
  }

  .filter-content {
    margin: 0;
  }

  .article-list {
    margin: 0;
  }

  .article-text h3 {
    font-size: 24px;
    line-height: 31px;
  }

  .simple-heading {
    margin-bottom: 16px;
  }

  .other-author-content h3 {
    font-size: 27px;
  }

  .other-author-item+.other-author-item {
    margin-top: 20px;
  }

  .subscription-box {
    padding: 26px;
  }

  .subscription-box h3 {
    font-size: 36px;
    line-height: 40px;
  }

  .subscription-box h4 {
    font-size: 24px;
  }
}