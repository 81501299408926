.bottom-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .panel-content {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .image-section img {
    max-width: 70px;
    height: auto;
  }
  
  .text-section {
    flex-grow: 1;
    padding: 0 20px;
    text-align: left;
  }
  
  .offer-main-text {
    font-size: 34px;
    line-height: 40px;
    font-family: var(--font-en);
    margin-bottom: 0;
    letter-spacing: -0.2px;
    font-weight: 400;
  }
  
  .offer-description {
    /*font-size: 14px;
    color: #333;*/
  }
  
  .original-price {
    text-decoration: line-through;
    color: #666;
  }
  
  .discount-price {
    color: #0074d9;
    font-weight: bold;
  }
  
  .cta-section {
    text-align: center;
  }
  
  .cta-button {
    background-color: #0074d9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #005bb5;
  }
  
  .sign-in {
    margin-top: 10px;
    font-size: 12px;
  }
  
  .sign-in a {
    /*color: #0074d9;
    text-decoration: none;*/
  }
  
  .sign-in a:hover {
    text-decoration: underline;
  }
  