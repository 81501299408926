/* General styles for the pop-up */
.popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Overlay effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Styling for the content of the pop-up */
.paymentsuccpopUpContent {
    position: relative;
    background-color: white;
    width: 748px;
    /* Adjust as needed */
    height: 634px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Logo container */
.logo {
    position: absolute;
    left: 0;
    overflow: hidden;
    /* Hides the overflow (50% of the left side) */
    height: 505px;
    /* Keep the image height the same */
}

/* Image inside the logo container */
.logo img {
    height: 505px;
    width: 505px;
    transform: translateX(-50%);
    /* Shift the image to the left by 50% */
}

/* Close button positioning */
.close-button {
    position: absolute;
    top: 34px;
    right: 27px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.thin-cross {
    width: 20px;
    /* Adjust size of the cross image */
    height: 20px;
}

/* Header styling */
.popUpHeader h2 {
    margin: 0;
    font-size: 52.56px;
    font-family: "EditorialNewItalic", sans-serif;
    text-align: center;
}

/* Body styling */
.popUpBody p {
    font-size: 25.69px;
    color: #333;
}

.text-body {
    width: 367px;
    margin-right: 76px;
}



/*Mobile view*/
@media (max-width: 768px) {
    .popUp {
    }

    .paymentsuccpopUpContent {
        height: 249px;
        width: 296px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 40px;
        align-items: flex-end;
    }

    .logo {
        height: 80%;
        width: 100%;
    }

    .text-body{
        width: 141px;
        margin-right: 24px;
    }

    .logo img {
        height: 199px;
        width: 199px;
        transform: translateX(-50%);
        /* Shift the image to the left by 50% */
    }

    .close-button {
        top: 13px;
        right: 13px;
    }

    .close-button img {
        width: 10.5px;
        height: 10.5px;
    }

    .popUpHeader h2 {
        font-size: 20px;
    }

    .popUpBody p {
        font-size: 10px;
        line-height: 1.5;
    }
}