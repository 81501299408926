.popUp.active {
    display: block;
    /* Show when active */
}

.popUp .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
    /* Optional: increase size for visibility */
}

/* Overlay background for the popup */
.popUp {
    position: fixed;
    top: 0;
    /* Align to the top */
    left: 0;
    /* Align to the left */
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent black background */
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 1000;
    /* Ensure it’s on top */
}

/* Popup content styling */
.modal-content {
    background-color: white;
    /* Popup background */
    padding: 20px;
    /* Padding inside the popup */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Shadow for depth */
    width: 90%;
    /* Width of the popup */
    max-width: 500px;
    /* Max width for larger screens */
    position: relative;
    /* For positioning the close button */
}

/* Close button styling */
.close-modal {
    cursor: pointer;
    position: absolute;
    top: 10px;
    /* Position it at the top */
    right: 10px;
    /* Position it on the right */
    border: none;
    /* No border */
    background: transparent;
    /* Transparent background */
    font-size: 16px;
    /* Font size for the button */
}

/*Login BG should be white */
.loginpopup {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 748px;
}



.close-modal img {
    margin-top: 28px;
    margin-right: 28px;
}

.loginpop-form {
    margin-top: 80px;
    width: 330px;
}

.forgot-form-modal {
    margin-top: 80px;
    width: 421px !important;
}

.forgot-form-modal h4 {
    width: 100%;
    margin-bottom: 20px !important;
    font-size: 51.56px !important;
}

.forgot-password-texts {
    margin: 0 -5px;
    margin-bottom: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgot-password-texts p {
    font-size: 25px;
}

.popUpContent{
    height: 748px;
    width: 633px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/*Mobile view for popups*/
@media (max-width: 768px) {
    .poptitle {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .close-modal img {
        height: 10px;
        width: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .login-form-modal .form-group .login-google {
        margin: 0;
        font-size: 14px !important;
        line-height: 20px;
        padding: 10px 20px;
    }

    .login-google img {
        height: 23px;
    }



    .popUp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loginpopup {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .loginpop-form {
        margin-top: 40px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .modal-content {
        width: 100%;
        /* Full width for smaller screens */
        max-width: 100%;
        /* Full width for smaller screens */
        padding: 10px;
        /* Padding for smaller screens */
    }

    .close-modal {
        top: 5px;
        /* Adjust top position for smaller screens */
        right: 5px;
        /* Adjust right position for smaller screens */
    }

    .loginpop-form h4 {
        width: 90%;
    }


    .forgot-form-modal {
        margin-top: 18px;
        width: 100% !important;
    }

    .forgot-form-modal h4 {
        margin-bottom: 20px !important;
        font-size: 28px !important;
    }

    .forgot-password-texts {
        margin: 0 -5px;
        margin-bottom: 21px;
    }

    .forgot-password-texts p {
        font-size: 14px;
        margin: 0 32px;
    }

}