/* Fullscreen modal styling */
.c-slider-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    /* Make sure it's on top of everything */
}
.c-slider-description {
    height: 30vh;
    overflow-y: scroll;
}
.c-slider-description p {
    color: white;;
}
/* Semi-transparent backdrop */
.c-slider-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    /* Dark overlay */
    z-index: 1;
    /* Below the slider content */
}

/* Slider container (inside the modal) */
.c-slider-container {
    position: relative;
    z-index: 2;
    /* Above the backdrop */
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: black;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.c-slider-description span {
    color: white !important;
}

/* Thumbnails */
.thumbnails {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 31px 27px;
}

.thumbnail {
    width: 80px;
    height: 80px;
    margin: 10px 0;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.thumbnails img {
    width: 263px;
    height: 174px;
    object-fit: cover;
}

.thumbnail.active {
    border: 2px solid gray;
    opacity: 1;
}

/* Right side: Main image, indicator, and description */
.current-slide {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 8.05vh;
    width: 80%;
}

/* Main image styling */
.main-image img {
    width: 55.3vw;
    height: 57.8vh;
    object-fit: contain;
}

/* Indicator container */
.indicator-container {
    display: flex;
    /* Align indicators in a row */
    justify-content: center;
    /* Center indicators */
    margin-bottom: 5.77vh;
    margin-top: 3.77vh;
    /* Space between indicators and description */
}

/* Indicator styling */
.indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: lightgrey;
    /* Default color for inactive */
    margin: 0 5px;
    /* Space between indicators */
    transition: background-color 0.3s ease;
    /* Smooth transition */
    cursor: pointer;
    /* Make indicators clickable */
}

.indicator.active {
    background-color: grey;
    /* Color for active indicator */
}

/* Description styling */
.description {
    max-width: 80%;

    color: white;
}

.description p {
    font-size: 23.84px;
    font-family: "PT Serif", serif;
}

/* Close button */
.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 3;
}


/* Thumbnails */
.thumbnails {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 20%;
    margin: 31px 27px;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #888 #333;
}

/* Hide scrollbar for WebKit browsers */
.thumbnails::-webkit-scrollbar {
    height: 8px;
    /* Height for horizontal scrollbar */
}

.thumbnails::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar color */
    border-radius: 10px;
    /* Rounded edges */
}

.thumbnails::-webkit-scrollbar-track {
    background: #333;
    /* Background color */
}

.thumbnail {
    width: 80px;
    height: 80px;
    margin: 2.5px 5px;
    /* Adjust margin for spacing */
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.nav-button {
    position: absolute;
    background-color: #707070;
    color: #000000;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 24px;
}

.nav-button.left {
    left: 47%;
}

.nav-button.right {
    right: 47%;
}


.nav-button:focus {
    outline: none;
}

.current-slide {
    position: relative;
    text-align: center;
}

.main-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


/*Mobile view*/
@media screen and (max-width: 768px) {

    .c-slider-container {
        flex-direction: column-reverse;
        padding: 0;
    }

    .current-slide {
        width: 100vw;
        height: 100vh;
        padding-top: 0;
    }

    .main-image {
        margin-top: 5vh;
    }
    .nav-button.left {
        left: 40%;
    }
    .nav-button.right {
        right: 40%;
    }

    .main-image img {
        width: 100%;
        height: 40vh;
        width: 100vw;
    }

    .indicator {
        width: 10px;
        height: 10px;
        margin: 5px 2px;
    }

    .thumbnails img {
        width: 163px;
        height: 74px;
        object-fit: cover;
    }

    .thumbnails {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        margin: 31px 27px;
        padding: 10px 0;
        scrollbar-width: thin;
        scrollbar-color: #888 #333;
        position: absolute;
    }

    .description {
        max-width: 80%;
        height: 30vh;
        overflow-y: auto;
    }

    .description p {
        font-size: 16px;
    }


    .thumbnails::-webkit-scrollbar {
        height: 8px;
        /* Height for horizontal scrollbar */
    }

    .thumbnails::-webkit-scrollbar-thumb {
        background-color: #888;
        /* Scrollbar color */
        border-radius: 10px;
        /* Rounded edges */
    }

    .thumbnails::-webkit-scrollbar-track {
        background: #333;
        /* Background color */
    }

    .thumbnail {
        width: 80px;
        height: 80px;
        margin: 10px 5px;
        /* Adjust margin for spacing */
        cursor: pointer;
        opacity: 0.6;
        transition: all 0.3s ease;
    }

}